import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChildren,
  QueryList,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import {FieldSetValue} from '../../../jspb/flow_api_pb';
import FieldSetEntry = FieldSetValue.FieldSetEntry;
import {FlowField} from '../../flow-field/flow-field';
import {FormArray} from '@angular/forms';
import {Field, FieldSet, Validator} from '../../../jspb/flow_pb';
import {FlowFieldComponent} from '../../flow-field/flow-field.component';
import {PairModel} from '../../../pair-wrapper/pair-model';
import TypeCase = Field.TypeCase;

@Component({
  selector: 'field-set-entry',
  templateUrl: './field-set-entry.component.html',
  styleUrls: ['./field-set-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldSetEntryComponent implements AfterViewInit {
  @Input() fieldSet: FieldSet;
  @Input() showCurrentFieldIndicator: boolean;
  @Input() validators: Validator[] = [];
  @Output() focusNextField: EventEmitter<void> = new EventEmitter();

  @ViewChildren(FlowFieldComponent) flowFields: QueryList<FlowFieldComponent>;

  TypeCase = TypeCase;

  fields: FormArray;

  constructor(public pairModel: PairModel) {}

  ngAfterViewInit() {
    this.fields = new FormArray(
      this.flowFields.map((flowField) => flowField.fields)
    );
  }

  reset() {
    for (const flowField of this.flowFields) {
      flowField.reset();
    }
  }

  focusFirstInput() {
    if (this.flowFields.length === 0) {
      return;
    }
    this.flowFields.get(0).focusFirstInput();
  }

  focusNextChildField(currentFieldIndex: number) {
    if (currentFieldIndex === this.fieldSet.getFieldsList().length - 1) {
      this.focusNextField.emit();
    } else {
      this.flowFields.get(currentFieldIndex + 1).focusFirstInput();
    }
  }

  getFieldSetEntryValue(): FieldSetEntry {
    const fieldSetEntryValue = new FieldSetEntry();
    fieldSetEntryValue.setFieldValuesList(
      this.flowFields
        .map((flowField) => flowField.getFieldValue())
        .filter((fieldValue) => fieldValue != null)
    );
    return fieldSetEntryValue;
  }
}
