// source: device_payload.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
var extensions_pb = require('./extensions_pb.js');
goog.object.extend(proto, extensions_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var sensors_pb = require('./sensors_pb.js');
goog.object.extend(proto, sensors_pb);
var entity_pb = require('./entity_pb.js');
goog.object.extend(proto, entity_pb);
goog.exportSymbol('proto.com.google.scout.chorus.proto.AggregatorDetails', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.BeaconPayload', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Beacons', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.BestLocation', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.DevicePayload', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.IoTCoreVendor', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.Measures', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.PayloadMetadata', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.BeaconPayload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.BeaconPayload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.BeaconPayload.displayName = 'proto.com.google.scout.chorus.proto.BeaconPayload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Beacons = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.Beacons.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.Beacons, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Beacons.displayName = 'proto.com.google.scout.chorus.proto.Beacons';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.DevicePayload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.DevicePayload.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.DevicePayload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.DevicePayload.displayName = 'proto.com.google.scout.chorus.proto.DevicePayload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.Measures = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.Measures.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.Measures, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.Measures.displayName = 'proto.com.google.scout.chorus.proto.Measures';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.AggregatorDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.AggregatorDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.AggregatorDetails.displayName = 'proto.com.google.scout.chorus.proto.AggregatorDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.BestLocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.BestLocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.BestLocation.displayName = 'proto.com.google.scout.chorus.proto.BestLocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.PayloadMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.PayloadMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.PayloadMetadata.displayName = 'proto.com.google.scout.chorus.proto.PayloadMetadata';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.BeaconPayload.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.BeaconPayload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.BeaconPayload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.BeaconPayload.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestampSeconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
    rxPowerDbm: jspb.Message.getFieldWithDefault(msg, 2, 0),
    advAddress: msg.getAdvAddress_asB64(),
    advData: msg.getAdvData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.BeaconPayload}
 */
proto.com.google.scout.chorus.proto.BeaconPayload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.BeaconPayload;
  return proto.com.google.scout.chorus.proto.BeaconPayload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.BeaconPayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.BeaconPayload}
 */
proto.com.google.scout.chorus.proto.BeaconPayload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTimestampSeconds(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setRxPowerDbm(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAdvAddress(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAdvData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.BeaconPayload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.BeaconPayload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.BeaconPayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.BeaconPayload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestampSeconds();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getRxPowerDbm();
  if (f !== 0) {
    writer.writeSint32(
      2,
      f
    );
  }
  f = message.getAdvAddress_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getAdvData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * optional uint32 timestamp_seconds = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.BeaconPayload.prototype.getTimestampSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.BeaconPayload} returns this
 */
proto.com.google.scout.chorus.proto.BeaconPayload.prototype.setTimestampSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional sint32 rx_power_dbm = 2;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.BeaconPayload.prototype.getRxPowerDbm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.BeaconPayload} returns this
 */
proto.com.google.scout.chorus.proto.BeaconPayload.prototype.setRxPowerDbm = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bytes adv_address = 3;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.BeaconPayload.prototype.getAdvAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes adv_address = 3;
 * This is a type-conversion wrapper around `getAdvAddress()`
 * @return {string}
 */
proto.com.google.scout.chorus.proto.BeaconPayload.prototype.getAdvAddress_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAdvAddress()));
};


/**
 * optional bytes adv_address = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAdvAddress()`
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.BeaconPayload.prototype.getAdvAddress_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAdvAddress()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.google.scout.chorus.proto.BeaconPayload} returns this
 */
proto.com.google.scout.chorus.proto.BeaconPayload.prototype.setAdvAddress = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional bytes adv_data = 4;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.BeaconPayload.prototype.getAdvData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes adv_data = 4;
 * This is a type-conversion wrapper around `getAdvData()`
 * @return {string}
 */
proto.com.google.scout.chorus.proto.BeaconPayload.prototype.getAdvData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAdvData()));
};


/**
 * optional bytes adv_data = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAdvData()`
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.BeaconPayload.prototype.getAdvData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAdvData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.google.scout.chorus.proto.BeaconPayload} returns this
 */
proto.com.google.scout.chorus.proto.BeaconPayload.prototype.setAdvData = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.Beacons.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Beacons.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Beacons.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Beacons} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Beacons.toObject = function(includeInstance, msg) {
  var f, obj = {
    beaconPayloadsList: jspb.Message.toObjectList(msg.getBeaconPayloadsList(),
    proto.com.google.scout.chorus.proto.BeaconPayload.toObject, includeInstance),
    lastKnownLocationTimestampSeconds: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Beacons}
 */
proto.com.google.scout.chorus.proto.Beacons.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Beacons;
  return proto.com.google.scout.chorus.proto.Beacons.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Beacons} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Beacons}
 */
proto.com.google.scout.chorus.proto.Beacons.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.google.scout.chorus.proto.BeaconPayload;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.BeaconPayload.deserializeBinaryFromReader);
      msg.addBeaconPayloads(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLastKnownLocationTimestampSeconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Beacons.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Beacons.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Beacons} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Beacons.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBeaconPayloadsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.google.scout.chorus.proto.BeaconPayload.serializeBinaryToWriter
    );
  }
  f = message.getLastKnownLocationTimestampSeconds();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * repeated BeaconPayload beacon_payloads = 1;
 * @return {!Array<!proto.com.google.scout.chorus.proto.BeaconPayload>}
 */
proto.com.google.scout.chorus.proto.Beacons.prototype.getBeaconPayloadsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.BeaconPayload>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.BeaconPayload, 1));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.BeaconPayload>} value
 * @return {!proto.com.google.scout.chorus.proto.Beacons} returns this
*/
proto.com.google.scout.chorus.proto.Beacons.prototype.setBeaconPayloadsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.BeaconPayload=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.BeaconPayload}
 */
proto.com.google.scout.chorus.proto.Beacons.prototype.addBeaconPayloads = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.google.scout.chorus.proto.BeaconPayload, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Beacons} returns this
 */
proto.com.google.scout.chorus.proto.Beacons.prototype.clearBeaconPayloadsList = function() {
  return this.setBeaconPayloadsList([]);
};


/**
 * optional uint32 last_known_location_timestamp_seconds = 2;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Beacons.prototype.getLastKnownLocationTimestampSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Beacons} returns this
 */
proto.com.google.scout.chorus.proto.Beacons.prototype.setLastKnownLocationTimestampSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.DevicePayload.repeatedFields_ = [6,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.DevicePayload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.DevicePayload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.DevicePayload.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    configVersion: msg.getConfigVersion_asB64(),
    fwVersion: msg.getFwVersion_asB64(),
    uploadId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    uploadTime: (f = msg.getUploadTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    measureList: jspb.Message.toObjectList(msg.getMeasureList(),
    proto.com.google.scout.chorus.proto.Measures.toObject, includeInstance),
    bleTxPowerDbm: (f = msg.getBleTxPowerDbm()) && common_pb.SInt32Value.toObject(includeInstance, f),
    bleRxPowerDbm: jspb.Message.getFieldWithDefault(msg, 9, 0),
    aggregatorDetailsList: jspb.Message.toObjectList(msg.getAggregatorDetailsList(),
    proto.com.google.scout.chorus.proto.AggregatorDetails.toObject, includeInstance),
    deviceType: jspb.Message.getFieldWithDefault(msg, 16, 0),
    iotCoreVendor: jspb.Message.getFieldWithDefault(msg, 18, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.DevicePayload}
 */
proto.com.google.scout.chorus.proto.DevicePayload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.DevicePayload;
  return proto.com.google.scout.chorus.proto.DevicePayload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.DevicePayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.DevicePayload}
 */
proto.com.google.scout.chorus.proto.DevicePayload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setConfigVersion(value);
      break;
    case 12:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFwVersion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadId(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUploadTime(value);
      break;
    case 6:
      var value = new proto.com.google.scout.chorus.proto.Measures;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Measures.deserializeBinaryFromReader);
      msg.addMeasure(value);
      break;
    case 15:
      var value = new common_pb.SInt32Value;
      reader.readMessage(value,common_pb.SInt32Value.deserializeBinaryFromReader);
      msg.setBleTxPowerDbm(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setBleRxPowerDbm(value);
      break;
    case 14:
      var value = new proto.com.google.scout.chorus.proto.AggregatorDetails;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.AggregatorDetails.deserializeBinaryFromReader);
      msg.addAggregatorDetails(value);
      break;
    case 16:
      var value = /** @type {!proto.com.google.scout.chorus.proto.Device.DeviceType} */ (reader.readEnum());
      msg.setDeviceType(value);
      break;
    case 18:
      var value = /** @type {!proto.com.google.scout.chorus.proto.IoTCoreVendor} */ (reader.readEnum());
      msg.setIotCoreVendor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.DevicePayload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.DevicePayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.DevicePayload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConfigVersion_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getFwVersion_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      12,
      f
    );
  }
  f = message.getUploadId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUploadTime();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMeasureList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.com.google.scout.chorus.proto.Measures.serializeBinaryToWriter
    );
  }
  f = message.getBleTxPowerDbm();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      common_pb.SInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getBleRxPowerDbm();
  if (f !== 0) {
    writer.writeSint32(
      9,
      f
    );
  }
  f = message.getAggregatorDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.com.google.scout.chorus.proto.AggregatorDetails.serializeBinaryToWriter
    );
  }
  f = message.getDeviceType();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getIotCoreVendor();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.DevicePayload} returns this
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes config_version = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.getConfigVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes config_version = 2;
 * This is a type-conversion wrapper around `getConfigVersion()`
 * @return {string}
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.getConfigVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getConfigVersion()));
};


/**
 * optional bytes config_version = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getConfigVersion()`
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.getConfigVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getConfigVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.google.scout.chorus.proto.DevicePayload} returns this
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.setConfigVersion = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional bytes fw_version = 12;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.getFwVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * optional bytes fw_version = 12;
 * This is a type-conversion wrapper around `getFwVersion()`
 * @return {string}
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.getFwVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFwVersion()));
};


/**
 * optional bytes fw_version = 12;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFwVersion()`
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.getFwVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFwVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.google.scout.chorus.proto.DevicePayload} returns this
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.setFwVersion = function(value) {
  return jspb.Message.setProto3BytesField(this, 12, value);
};


/**
 * optional string upload_id = 4;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.getUploadId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.DevicePayload} returns this
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.setUploadId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp upload_time = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.getUploadTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.DevicePayload} returns this
*/
proto.com.google.scout.chorus.proto.DevicePayload.prototype.setUploadTime = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.DevicePayload} returns this
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.clearUploadTime = function() {
  return this.setUploadTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.hasUploadTime = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated Measures measure = 6;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Measures>}
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.getMeasureList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Measures>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.Measures, 6));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Measures>} value
 * @return {!proto.com.google.scout.chorus.proto.DevicePayload} returns this
*/
proto.com.google.scout.chorus.proto.DevicePayload.prototype.setMeasureList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Measures=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Measures}
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.addMeasure = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.com.google.scout.chorus.proto.Measures, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.DevicePayload} returns this
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.clearMeasureList = function() {
  return this.setMeasureList([]);
};


/**
 * optional SInt32Value ble_tx_power_dbm = 15;
 * @return {?proto.com.google.scout.chorus.proto.SInt32Value}
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.getBleTxPowerDbm = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.SInt32Value} */ (
    jspb.Message.getWrapperField(this, common_pb.SInt32Value, 15));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.SInt32Value|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.DevicePayload} returns this
*/
proto.com.google.scout.chorus.proto.DevicePayload.prototype.setBleTxPowerDbm = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.DevicePayload} returns this
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.clearBleTxPowerDbm = function() {
  return this.setBleTxPowerDbm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.hasBleTxPowerDbm = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional sint32 ble_rx_power_dbm = 9;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.getBleRxPowerDbm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.DevicePayload} returns this
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.setBleRxPowerDbm = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated AggregatorDetails aggregator_details = 14;
 * @return {!Array<!proto.com.google.scout.chorus.proto.AggregatorDetails>}
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.getAggregatorDetailsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.AggregatorDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.AggregatorDetails, 14));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.AggregatorDetails>} value
 * @return {!proto.com.google.scout.chorus.proto.DevicePayload} returns this
*/
proto.com.google.scout.chorus.proto.DevicePayload.prototype.setAggregatorDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.AggregatorDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.AggregatorDetails}
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.addAggregatorDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.com.google.scout.chorus.proto.AggregatorDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.DevicePayload} returns this
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.clearAggregatorDetailsList = function() {
  return this.setAggregatorDetailsList([]);
};


/**
 * optional Device.DeviceType device_type = 16;
 * @return {!proto.com.google.scout.chorus.proto.Device.DeviceType}
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.getDeviceType = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.Device.DeviceType} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Device.DeviceType} value
 * @return {!proto.com.google.scout.chorus.proto.DevicePayload} returns this
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.setDeviceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional IoTCoreVendor iot_core_vendor = 18;
 * @return {!proto.com.google.scout.chorus.proto.IoTCoreVendor}
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.getIotCoreVendor = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.IoTCoreVendor} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.IoTCoreVendor} value
 * @return {!proto.com.google.scout.chorus.proto.DevicePayload} returns this
 */
proto.com.google.scout.chorus.proto.DevicePayload.prototype.setIotCoreVendor = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.Measures.repeatedFields_ = [8,23,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.Measures.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.Measures} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Measures.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordTimestampSeconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
    temperature: (f = msg.getTemperature()) && sensors_pb.Temperature.toObject(includeInstance, f),
    light: (f = msg.getLight()) && sensors_pb.Light.toObject(includeInstance, f),
    accelerometer: (f = msg.getAccelerometer()) && sensors_pb.Accelerometer.toObject(includeInstance, f),
    battery: (f = msg.getBattery()) && sensors_pb.Battery.toObject(includeInstance, f),
    pressure: (f = msg.getPressure()) && sensors_pb.Pressure.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && sensors_pb.Location.toObject(includeInstance, f),
    humidity: (f = msg.getHumidity()) && sensors_pb.Humidity.toObject(includeInstance, f),
    lteQuality: (f = msg.getLteQuality()) && sensors_pb.LteQuality.toObject(includeInstance, f),
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    sensors_pb.EventConfig.toObject, includeInstance),
    debugLogsList: (f = jspb.Message.getRepeatedField(msg, 23)) == null ? undefined : f,
    beaconsv2List: jspb.Message.toObjectList(msg.getBeaconsv2List(),
    proto.com.google.scout.chorus.proto.DevicePayload.toObject, includeInstance),
    recordTime: (f = msg.getRecordTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    movementCounter: (f = msg.getMovementCounter()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
    sequenceNumber: (f = msg.getSequenceNumber()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
    uploadTime: (f = msg.getUploadTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    firmwareVersionString: jspb.Message.getFieldWithDefault(msg, 24, ""),
    configVersionString: jspb.Message.getFieldWithDefault(msg, 25, ""),
    deviceId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    deviceType: jspb.Message.getFieldWithDefault(msg, 15, 0),
    aggregatorDetails: (f = msg.getAggregatorDetails()) && proto.com.google.scout.chorus.proto.AggregatorDetails.toObject(includeInstance, f),
    aggregatorDetailsModificationTime: (f = msg.getAggregatorDetailsModificationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    bestOwnLocation: (f = msg.getBestOwnLocation()) && sensors_pb.PointLocation.toObject(includeInstance, f),
    bestLocation: (f = msg.getBestLocation()) && proto.com.google.scout.chorus.proto.BestLocation.toObject(includeInstance, f),
    startedStoppedMoving: (f = msg.getStartedStoppedMoving()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    lteOperatorString: jspb.Message.getFieldWithDefault(msg, 26, ""),
    beacons: (f = msg.getBeacons()) && proto.com.google.scout.chorus.proto.Beacons.toObject(includeInstance, f),
    isTampered: jspb.Message.getBooleanFieldWithDefault(msg, 31, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.Measures}
 */
proto.com.google.scout.chorus.proto.Measures.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.Measures;
  return proto.com.google.scout.chorus.proto.Measures.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.Measures} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.Measures}
 */
proto.com.google.scout.chorus.proto.Measures.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecordTimestampSeconds(value);
      break;
    case 2:
      var value = new sensors_pb.Temperature;
      reader.readMessage(value,sensors_pb.Temperature.deserializeBinaryFromReader);
      msg.setTemperature(value);
      break;
    case 3:
      var value = new sensors_pb.Light;
      reader.readMessage(value,sensors_pb.Light.deserializeBinaryFromReader);
      msg.setLight(value);
      break;
    case 4:
      var value = new sensors_pb.Accelerometer;
      reader.readMessage(value,sensors_pb.Accelerometer.deserializeBinaryFromReader);
      msg.setAccelerometer(value);
      break;
    case 5:
      var value = new sensors_pb.Battery;
      reader.readMessage(value,sensors_pb.Battery.deserializeBinaryFromReader);
      msg.setBattery(value);
      break;
    case 6:
      var value = new sensors_pb.Pressure;
      reader.readMessage(value,sensors_pb.Pressure.deserializeBinaryFromReader);
      msg.setPressure(value);
      break;
    case 7:
      var value = new sensors_pb.Location;
      reader.readMessage(value,sensors_pb.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 29:
      var value = new sensors_pb.Humidity;
      reader.readMessage(value,sensors_pb.Humidity.deserializeBinaryFromReader);
      msg.setHumidity(value);
      break;
    case 9:
      var value = new sensors_pb.LteQuality;
      reader.readMessage(value,sensors_pb.LteQuality.deserializeBinaryFromReader);
      msg.setLteQuality(value);
      break;
    case 8:
      var value = new sensors_pb.EventConfig;
      reader.readMessage(value,sensors_pb.EventConfig.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.addDebugLogs(value);
      break;
    case 10:
      var value = new proto.com.google.scout.chorus.proto.DevicePayload;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.DevicePayload.deserializeBinaryFromReader);
      msg.addBeaconsv2(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRecordTime(value);
      break;
    case 19:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setMovementCounter(value);
      break;
    case 20:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setSequenceNumber(value);
      break;
    case 22:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUploadTime(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirmwareVersionString(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfigVersionString(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 15:
      var value = /** @type {!proto.com.google.scout.chorus.proto.Device.DeviceType} */ (reader.readEnum());
      msg.setDeviceType(value);
      break;
    case 12:
      var value = new proto.com.google.scout.chorus.proto.AggregatorDetails;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.AggregatorDetails.deserializeBinaryFromReader);
      msg.setAggregatorDetails(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAggregatorDetailsModificationTime(value);
      break;
    case 17:
      var value = new sensors_pb.PointLocation;
      reader.readMessage(value,sensors_pb.PointLocation.deserializeBinaryFromReader);
      msg.setBestOwnLocation(value);
      break;
    case 18:
      var value = new proto.com.google.scout.chorus.proto.BestLocation;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.BestLocation.deserializeBinaryFromReader);
      msg.setBestLocation(value);
      break;
    case 21:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setStartedStoppedMoving(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setLteOperatorString(value);
      break;
    case 30:
      var value = new proto.com.google.scout.chorus.proto.Beacons;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.Beacons.deserializeBinaryFromReader);
      msg.setBeacons(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTampered(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.Measures.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.Measures} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.Measures.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordTimestampSeconds();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTemperature();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sensors_pb.Temperature.serializeBinaryToWriter
    );
  }
  f = message.getLight();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      sensors_pb.Light.serializeBinaryToWriter
    );
  }
  f = message.getAccelerometer();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      sensors_pb.Accelerometer.serializeBinaryToWriter
    );
  }
  f = message.getBattery();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      sensors_pb.Battery.serializeBinaryToWriter
    );
  }
  f = message.getPressure();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      sensors_pb.Pressure.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      sensors_pb.Location.serializeBinaryToWriter
    );
  }
  f = message.getHumidity();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      sensors_pb.Humidity.serializeBinaryToWriter
    );
  }
  f = message.getLteQuality();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      sensors_pb.LteQuality.serializeBinaryToWriter
    );
  }
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      sensors_pb.EventConfig.serializeBinaryToWriter
    );
  }
  f = message.getDebugLogsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      23,
      f
    );
  }
  f = message.getBeaconsv2List();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.com.google.scout.chorus.proto.DevicePayload.serializeBinaryToWriter
    );
  }
  f = message.getRecordTime();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMovementCounter();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getSequenceNumber();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getUploadTime();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFirmwareVersionString();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getConfigVersionString();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDeviceType();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getAggregatorDetails();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.com.google.scout.chorus.proto.AggregatorDetails.serializeBinaryToWriter
    );
  }
  f = message.getAggregatorDetailsModificationTime();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBestOwnLocation();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      sensors_pb.PointLocation.serializeBinaryToWriter
    );
  }
  f = message.getBestLocation();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.com.google.scout.chorus.proto.BestLocation.serializeBinaryToWriter
    );
  }
  f = message.getStartedStoppedMoving();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getLteOperatorString();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getBeacons();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.com.google.scout.chorus.proto.Beacons.serializeBinaryToWriter
    );
  }
  f = message.getIsTampered();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
};


/**
 * optional uint32 record_timestamp_seconds = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getRecordTimestampSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.setRecordTimestampSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Temperature temperature = 2;
 * @return {?proto.com.google.scout.chorus.proto.Temperature}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getTemperature = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Temperature} */ (
    jspb.Message.getWrapperField(this, sensors_pb.Temperature, 2));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Temperature|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
*/
proto.com.google.scout.chorus.proto.Measures.prototype.setTemperature = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.clearTemperature = function() {
  return this.setTemperature(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.hasTemperature = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Light light = 3;
 * @return {?proto.com.google.scout.chorus.proto.Light}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getLight = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Light} */ (
    jspb.Message.getWrapperField(this, sensors_pb.Light, 3));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Light|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
*/
proto.com.google.scout.chorus.proto.Measures.prototype.setLight = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.clearLight = function() {
  return this.setLight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.hasLight = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Accelerometer accelerometer = 4;
 * @return {?proto.com.google.scout.chorus.proto.Accelerometer}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getAccelerometer = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Accelerometer} */ (
    jspb.Message.getWrapperField(this, sensors_pb.Accelerometer, 4));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Accelerometer|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
*/
proto.com.google.scout.chorus.proto.Measures.prototype.setAccelerometer = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.clearAccelerometer = function() {
  return this.setAccelerometer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.hasAccelerometer = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Battery battery = 5;
 * @return {?proto.com.google.scout.chorus.proto.Battery}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getBattery = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Battery} */ (
    jspb.Message.getWrapperField(this, sensors_pb.Battery, 5));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Battery|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
*/
proto.com.google.scout.chorus.proto.Measures.prototype.setBattery = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.clearBattery = function() {
  return this.setBattery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.hasBattery = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Pressure pressure = 6;
 * @return {?proto.com.google.scout.chorus.proto.Pressure}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getPressure = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Pressure} */ (
    jspb.Message.getWrapperField(this, sensors_pb.Pressure, 6));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Pressure|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
*/
proto.com.google.scout.chorus.proto.Measures.prototype.setPressure = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.clearPressure = function() {
  return this.setPressure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.hasPressure = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Location location = 7;
 * @return {?proto.com.google.scout.chorus.proto.Location}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getLocation = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Location} */ (
    jspb.Message.getWrapperField(this, sensors_pb.Location, 7));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Location|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
*/
proto.com.google.scout.chorus.proto.Measures.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Humidity humidity = 29;
 * @return {?proto.com.google.scout.chorus.proto.Humidity}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getHumidity = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Humidity} */ (
    jspb.Message.getWrapperField(this, sensors_pb.Humidity, 29));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Humidity|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
*/
proto.com.google.scout.chorus.proto.Measures.prototype.setHumidity = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.clearHumidity = function() {
  return this.setHumidity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.hasHumidity = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional LteQuality lte_quality = 9;
 * @return {?proto.com.google.scout.chorus.proto.LteQuality}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getLteQuality = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.LteQuality} */ (
    jspb.Message.getWrapperField(this, sensors_pb.LteQuality, 9));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.LteQuality|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
*/
proto.com.google.scout.chorus.proto.Measures.prototype.setLteQuality = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.clearLteQuality = function() {
  return this.setLteQuality(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.hasLteQuality = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated EventConfig events = 8;
 * @return {!Array<!proto.com.google.scout.chorus.proto.EventConfig>}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.EventConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, sensors_pb.EventConfig, 8));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.EventConfig>} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
*/
proto.com.google.scout.chorus.proto.Measures.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.EventConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.EventConfig}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.com.google.scout.chorus.proto.EventConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * repeated string debug_logs = 23;
 * @return {!Array<string>}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getDebugLogsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 23));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.setDebugLogsList = function(value) {
  return jspb.Message.setField(this, 23, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.addDebugLogs = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 23, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.clearDebugLogsList = function() {
  return this.setDebugLogsList([]);
};


/**
 * repeated DevicePayload beaconsV2 = 10;
 * @return {!Array<!proto.com.google.scout.chorus.proto.DevicePayload>}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getBeaconsv2List = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.DevicePayload>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.DevicePayload, 10));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.DevicePayload>} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
*/
proto.com.google.scout.chorus.proto.Measures.prototype.setBeaconsv2List = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.DevicePayload=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.DevicePayload}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.addBeaconsv2 = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.com.google.scout.chorus.proto.DevicePayload, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.clearBeaconsv2List = function() {
  return this.setBeaconsv2List([]);
};


/**
 * optional google.protobuf.Timestamp record_time = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getRecordTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
*/
proto.com.google.scout.chorus.proto.Measures.prototype.setRecordTime = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.clearRecordTime = function() {
  return this.setRecordTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.hasRecordTime = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.UInt32Value movement_counter = 19;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getMovementCounter = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 19));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
*/
proto.com.google.scout.chorus.proto.Measures.prototype.setMovementCounter = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.clearMovementCounter = function() {
  return this.setMovementCounter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.hasMovementCounter = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional google.protobuf.UInt32Value sequence_number = 20;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getSequenceNumber = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 20));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
*/
proto.com.google.scout.chorus.proto.Measures.prototype.setSequenceNumber = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.clearSequenceNumber = function() {
  return this.setSequenceNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.hasSequenceNumber = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional google.protobuf.Timestamp upload_time = 22;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getUploadTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 22));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
*/
proto.com.google.scout.chorus.proto.Measures.prototype.setUploadTime = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.clearUploadTime = function() {
  return this.setUploadTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.hasUploadTime = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional string firmware_version_string = 24;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getFirmwareVersionString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.setFirmwareVersionString = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string config_version_string = 25;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getConfigVersionString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.setConfigVersionString = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string device_id = 13;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional Device.DeviceType device_type = 15;
 * @return {!proto.com.google.scout.chorus.proto.Device.DeviceType}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getDeviceType = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.Device.DeviceType} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Device.DeviceType} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.setDeviceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional AggregatorDetails aggregator_details = 12;
 * @return {?proto.com.google.scout.chorus.proto.AggregatorDetails}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getAggregatorDetails = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.AggregatorDetails} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.AggregatorDetails, 12));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.AggregatorDetails|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
*/
proto.com.google.scout.chorus.proto.Measures.prototype.setAggregatorDetails = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.clearAggregatorDetails = function() {
  return this.setAggregatorDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.hasAggregatorDetails = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp aggregator_details_modification_time = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getAggregatorDetailsModificationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
*/
proto.com.google.scout.chorus.proto.Measures.prototype.setAggregatorDetailsModificationTime = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.clearAggregatorDetailsModificationTime = function() {
  return this.setAggregatorDetailsModificationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.hasAggregatorDetailsModificationTime = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional PointLocation best_own_location = 17;
 * @return {?proto.com.google.scout.chorus.proto.PointLocation}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getBestOwnLocation = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.PointLocation} */ (
    jspb.Message.getWrapperField(this, sensors_pb.PointLocation, 17));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.PointLocation|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
*/
proto.com.google.scout.chorus.proto.Measures.prototype.setBestOwnLocation = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.clearBestOwnLocation = function() {
  return this.setBestOwnLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.hasBestOwnLocation = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional BestLocation best_location = 18;
 * @return {?proto.com.google.scout.chorus.proto.BestLocation}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getBestLocation = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.BestLocation} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.BestLocation, 18));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.BestLocation|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
*/
proto.com.google.scout.chorus.proto.Measures.prototype.setBestLocation = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.clearBestLocation = function() {
  return this.setBestLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.hasBestLocation = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional google.protobuf.BoolValue started_stopped_moving = 21;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getStartedStoppedMoving = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 21));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
*/
proto.com.google.scout.chorus.proto.Measures.prototype.setStartedStoppedMoving = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.clearStartedStoppedMoving = function() {
  return this.setStartedStoppedMoving(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.hasStartedStoppedMoving = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional string lte_operator_string = 26;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getLteOperatorString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.setLteOperatorString = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional Beacons beacons = 30;
 * @return {?proto.com.google.scout.chorus.proto.Beacons}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getBeacons = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Beacons} */ (
    jspb.Message.getWrapperField(this, proto.com.google.scout.chorus.proto.Beacons, 30));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Beacons|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
*/
proto.com.google.scout.chorus.proto.Measures.prototype.setBeacons = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.clearBeacons = function() {
  return this.setBeacons(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.hasBeacons = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional bool is_tampered = 31;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.Measures.prototype.getIsTampered = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.Measures} returns this
 */
proto.com.google.scout.chorus.proto.Measures.prototype.setIsTampered = function(value) {
  return jspb.Message.setProto3BooleanField(this, 31, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.AggregatorDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.AggregatorDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    aggregatorDeviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    aggregatorRecordTime: (f = msg.getAggregatorRecordTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    rxPowerDbm: jspb.Message.getFieldWithDefault(msg, 3, 0),
    txPowerDbm: (f = msg.getTxPowerDbm()) && common_pb.SInt32Value.toObject(includeInstance, f),
    aggregatorLocation: (f = msg.getAggregatorLocation()) && sensors_pb.PointLocation.toObject(includeInstance, f),
    modificationTime: (f = msg.getModificationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deviceId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    recordTime: (f = msg.getRecordTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deviceType: jspb.Message.getFieldWithDefault(msg, 11, 0),
    aggregatorDeviceType: jspb.Message.getFieldWithDefault(msg, 12, 0),
    uploadId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    startedStoppedMoving: (f = msg.getStartedStoppedMoving()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.AggregatorDetails}
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.AggregatorDetails;
  return proto.com.google.scout.chorus.proto.AggregatorDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.AggregatorDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.AggregatorDetails}
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAggregatorDeviceId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAggregatorRecordTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setRxPowerDbm(value);
      break;
    case 13:
      var value = new common_pb.SInt32Value;
      reader.readMessage(value,common_pb.SInt32Value.deserializeBinaryFromReader);
      msg.setTxPowerDbm(value);
      break;
    case 5:
      var value = new sensors_pb.PointLocation;
      reader.readMessage(value,sensors_pb.PointLocation.deserializeBinaryFromReader);
      msg.setAggregatorLocation(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setModificationTime(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRecordTime(value);
      break;
    case 11:
      var value = /** @type {!proto.com.google.scout.chorus.proto.Device.DeviceType} */ (reader.readEnum());
      msg.setDeviceType(value);
      break;
    case 12:
      var value = /** @type {!proto.com.google.scout.chorus.proto.Device.DeviceType} */ (reader.readEnum());
      msg.setAggregatorDeviceType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadId(value);
      break;
    case 16:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setStartedStoppedMoving(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.AggregatorDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.AggregatorDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAggregatorDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAggregatorRecordTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRxPowerDbm();
  if (f !== 0) {
    writer.writeSint32(
      3,
      f
    );
  }
  f = message.getTxPowerDbm();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      common_pb.SInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getAggregatorLocation();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      sensors_pb.PointLocation.serializeBinaryToWriter
    );
  }
  f = message.getModificationTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRecordTime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeviceType();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getAggregatorDeviceType();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getUploadId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getStartedStoppedMoving();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string aggregator_device_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.getAggregatorDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.AggregatorDetails} returns this
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.setAggregatorDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp aggregator_record_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.getAggregatorRecordTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.AggregatorDetails} returns this
*/
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.setAggregatorRecordTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.AggregatorDetails} returns this
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.clearAggregatorRecordTime = function() {
  return this.setAggregatorRecordTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.hasAggregatorRecordTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional sint32 rx_power_dbm = 3;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.getRxPowerDbm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.AggregatorDetails} returns this
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.setRxPowerDbm = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional SInt32Value tx_power_dbm = 13;
 * @return {?proto.com.google.scout.chorus.proto.SInt32Value}
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.getTxPowerDbm = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.SInt32Value} */ (
    jspb.Message.getWrapperField(this, common_pb.SInt32Value, 13));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.SInt32Value|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.AggregatorDetails} returns this
*/
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.setTxPowerDbm = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.AggregatorDetails} returns this
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.clearTxPowerDbm = function() {
  return this.setTxPowerDbm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.hasTxPowerDbm = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional PointLocation aggregator_location = 5;
 * @return {?proto.com.google.scout.chorus.proto.PointLocation}
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.getAggregatorLocation = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.PointLocation} */ (
    jspb.Message.getWrapperField(this, sensors_pb.PointLocation, 5));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.PointLocation|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.AggregatorDetails} returns this
*/
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.setAggregatorLocation = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.AggregatorDetails} returns this
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.clearAggregatorLocation = function() {
  return this.setAggregatorLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.hasAggregatorLocation = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp modification_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.getModificationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.AggregatorDetails} returns this
*/
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.setModificationTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.AggregatorDetails} returns this
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.clearModificationTime = function() {
  return this.setModificationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.hasModificationTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string device_id = 8;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.AggregatorDetails} returns this
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp record_time = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.getRecordTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.AggregatorDetails} returns this
*/
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.setRecordTime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.AggregatorDetails} returns this
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.clearRecordTime = function() {
  return this.setRecordTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.hasRecordTime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Device.DeviceType device_type = 11;
 * @return {!proto.com.google.scout.chorus.proto.Device.DeviceType}
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.getDeviceType = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.Device.DeviceType} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Device.DeviceType} value
 * @return {!proto.com.google.scout.chorus.proto.AggregatorDetails} returns this
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.setDeviceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional Device.DeviceType aggregator_device_type = 12;
 * @return {!proto.com.google.scout.chorus.proto.Device.DeviceType}
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.getAggregatorDeviceType = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.Device.DeviceType} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Device.DeviceType} value
 * @return {!proto.com.google.scout.chorus.proto.AggregatorDetails} returns this
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.setAggregatorDeviceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional string upload_id = 10;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.getUploadId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.AggregatorDetails} returns this
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.setUploadId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional google.protobuf.BoolValue started_stopped_moving = 16;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.getStartedStoppedMoving = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 16));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.AggregatorDetails} returns this
*/
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.setStartedStoppedMoving = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.AggregatorDetails} returns this
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.clearStartedStoppedMoving = function() {
  return this.setStartedStoppedMoving(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.AggregatorDetails.prototype.hasStartedStoppedMoving = function() {
  return jspb.Message.getField(this, 16) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.BestLocation.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.BestLocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.BestLocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.BestLocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourceDeviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    locationTime: (f = msg.getLocationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && sensors_pb.PointLocation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.BestLocation}
 */
proto.com.google.scout.chorus.proto.BestLocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.BestLocation;
  return proto.com.google.scout.chorus.proto.BestLocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.BestLocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.BestLocation}
 */
proto.com.google.scout.chorus.proto.BestLocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceDeviceId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLocationTime(value);
      break;
    case 3:
      var value = new sensors_pb.PointLocation;
      reader.readMessage(value,sensors_pb.PointLocation.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.BestLocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.BestLocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.BestLocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.BestLocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourceDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocationTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      sensors_pb.PointLocation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string source_device_id = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.BestLocation.prototype.getSourceDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.BestLocation} returns this
 */
proto.com.google.scout.chorus.proto.BestLocation.prototype.setSourceDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp location_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.BestLocation.prototype.getLocationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.BestLocation} returns this
*/
proto.com.google.scout.chorus.proto.BestLocation.prototype.setLocationTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.BestLocation} returns this
 */
proto.com.google.scout.chorus.proto.BestLocation.prototype.clearLocationTime = function() {
  return this.setLocationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.BestLocation.prototype.hasLocationTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PointLocation location = 3;
 * @return {?proto.com.google.scout.chorus.proto.PointLocation}
 */
proto.com.google.scout.chorus.proto.BestLocation.prototype.getLocation = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.PointLocation} */ (
    jspb.Message.getWrapperField(this, sensors_pb.PointLocation, 3));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.PointLocation|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.BestLocation} returns this
*/
proto.com.google.scout.chorus.proto.BestLocation.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.BestLocation} returns this
 */
proto.com.google.scout.chorus.proto.BestLocation.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.BestLocation.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.PayloadMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.PayloadMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.PayloadMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.PayloadMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    receivedTime: (f = msg.getReceivedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    orgName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orgId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.PayloadMetadata}
 */
proto.com.google.scout.chorus.proto.PayloadMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.PayloadMetadata;
  return proto.com.google.scout.chorus.proto.PayloadMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.PayloadMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.PayloadMetadata}
 */
proto.com.google.scout.chorus.proto.PayloadMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setReceivedTime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.PayloadMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.PayloadMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.PayloadMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.PayloadMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceivedTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOrgName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrgId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp received_time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.PayloadMetadata.prototype.getReceivedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.PayloadMetadata} returns this
*/
proto.com.google.scout.chorus.proto.PayloadMetadata.prototype.setReceivedTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.PayloadMetadata} returns this
 */
proto.com.google.scout.chorus.proto.PayloadMetadata.prototype.clearReceivedTime = function() {
  return this.setReceivedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.PayloadMetadata.prototype.hasReceivedTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string org_name = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.PayloadMetadata.prototype.getOrgName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.PayloadMetadata} returns this
 */
proto.com.google.scout.chorus.proto.PayloadMetadata.prototype.setOrgName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string org_id = 3;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.PayloadMetadata.prototype.getOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.PayloadMetadata} returns this
 */
proto.com.google.scout.chorus.proto.PayloadMetadata.prototype.setOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.com.google.scout.chorus.proto.IoTCoreVendor = {
  UNDEFINED: 0,
  GCP: 1,
  CLEARBLADE: 2
};

goog.object.extend(exports, proto.com.google.scout.chorus.proto);
