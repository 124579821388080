import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {Chart} from 'chart.js';
import 'chartjs-adapter-moment';
import {Observable, Subject, Subscription} from 'rxjs';
import {ChartData, ChartPoint} from './chart-data';
import {
  getFirstIncidentIndexPessimistic,
  getLastIncidentIndexPessimistic,
} from '../shared/alert-utils';
import {QueryParamService} from '../services/query-param-service';
import {first} from 'rxjs/operators';
import {TimeZoneService} from '../services/time-zone-service';

const GOOGLE_WHITE = 'rgb(255,255,255)';
const GOOGLE_BLUE_100 = 'rgba(210,227,252,0.4)';
const GOOGLE_BLUE_500 = 'rgba(66,133,244,255)';
const GOOGLE_RED_100 = 'rgba(250,210,207,0.4)';
const GOOGLE_RED_400 = 'rgba(238,103,92,255)';
// Amount of padding to apply to the min/max values of the chart.
// For example, if the minimum data point is 22, the axis minimum
// would be 22 - BOUND_PADDING. Exported for tests.
export const BOUND_PADDING = 5;
export const REGULAR_DATASET_INDEX = 0;
export const INCIDENT_DATASET_INDEX = 1;
export const MIN_THRESHOLD_DATASET_INDEX = 2;
export const MAX_THRESHOLD_DATASET_INDEX = 3;

const SEC_IN_MINUTE = 60;
const SEC_IN_HOUR = SEC_IN_MINUTE * 60;
const SEC_IN_DAY = SEC_IN_HOUR * 24;
const SEC_IN_WEEK = SEC_IN_DAY * 7;
// Not exactly, but this is an approximation for scale.
const SEC_IN_MONTH = SEC_IN_DAY * 30;
const SEC_IN_YEAR = SEC_IN_DAY * 365;

// Time offset to use when inserting a gap in the data. The number is largely
// arbitrarily: it just needs to be some number of ms before the oldest data
// point.
const CHART_GAP_MS_OFFSET = 1;

// Name of a "secret" query parameter used for overriding the time threshold for
// whether to show a gap between two data points. This is never programmatically
// set and must be manually typed in by the user, most likely Chorus teammates.
// Exported for tests.
export const GAP_MINS_PARAM_NAME = 'gap_mins';

// Name of a "secret" query parameter used to unconditionally show individual
// data points on the chart. See "forceShowPoints" below.
export const FORCE_SHOW_POINTS_PARAM_NAME = 'force_points';

const THRESHOLD_LINE_OPTIONS = {
  // Color of the line.
  borderColor: GOOGLE_RED_400,
  // Dash size and spacing.
  borderDash: [5, 5],
  borderWidth: 2,
  // Don't fill the area under the line
  fill: false,
  // Draw straight lines; no curves.
  lineTension: 0,
  // Zero values so the individual points on the line are not
  // displayed and are non-interactive.
  pointBorderWidth: 0,
  pointRadius: 0,
  pointHitRadius: 0,
  spanGaps: true,
};

@Component({
  selector: 'measure-chart',
  templateUrl: './measure-chart.component.html',
  styleUrls: ['./measure-chart.component.scss'],
})
export class MeasureChartComponent implements AfterViewInit, OnDestroy {
  @ViewChild('measureChart', {static: true}) chartRef: ElementRef;
  @Input() chartData$: Observable<ChartData>;
  @Input() yAxisMin?: number;
  @Input() yAxisMax?: number;
  @Input() incidentTimeSec?: number | null;
  @Input() resolutionTimeSec?: number | null;
  @Input() minValueThreshold?: number | null;
  @Input() maxValueThreshold?: number | null;
  @Input() yAxisLabels?: Map<number, string> | null;
  @Input() fillMode: string | boolean = 'origin';
  /** Note: emits *at least once* when the state changes. */
  @Output() hasDataToShow: EventEmitter<boolean> = new EventEmitter<boolean>();

  private subscriptions: Subscription = new Subscription();
  private unsubscribe$ = new Subject();
  // Min and max values actually supplied to the chart. Takes into account
  // suggestions in case the min and max are not provided.
  private chartMin: number | null = null;
  private chartMax: number | null = null;

  chart: Chart;
  private regularData: ChartPoint[] = [];
  private incidentData: ChartPoint[] = [];

  // By default, we use one hour and one minute to allow for slight delays in
  // check-ins for devices that check in hourly.
  // This can be overridden by a "secret" query parameter that must be manually
  // typed in.
  private maxTimeGapMs: number = (SEC_IN_HOUR + SEC_IN_MINUTE) * 1e3;

  // By default, we decide whether to show individual data points on the chart
  // based on the data density. If this is set (via query parameter), then we
  // unconditionally show the data points.
  private forceShowPoints: boolean = false;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private queryParamService: QueryParamService,
    private timeZoneService: TimeZoneService
  ) {}

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    this.initChart();
    this.subscriptions.add(
      this.timeZoneService.selectedTimeZone$.subscribe({
        next: () => this.chart.update(),
      })
    );

    this.queryParamService
      .getNumberParam(GAP_MINS_PARAM_NAME)
      .pipe(first())
      .subscribe({
        next: (gapMins) => {
          if (!gapMins) return;

          this.maxTimeGapMs = gapMins * SEC_IN_MINUTE * 1e3;
        },
      });

    this.queryParamService
      // TODO(grantuy|patkbriggs): Maybe accept values like "1" as well.
      .getBooleanParam(FORCE_SHOW_POINTS_PARAM_NAME)
      .pipe(first())
      .subscribe({
        next: (showPoints) => {
          if (!showPoints) return;

          this.forceShowPoints = true;
        },
      });

    this.subscriptions.add(
      this.chartData$.subscribe({
        next: (chartData: ChartData) => {
          if (!chartData.isUpdate) {
            this.resetChart(chartData);
          }
          this.updateData(chartData);
          this.updateXAxisBounds(chartData);
          this.updateYAxisBounds(chartData);
          this.updatePointStylingForDataDensity();
          this.updateMinAndMaxThresholds();
          this.chart.update();
        },
      })
    );
  }

  private resetChart(chartData: ChartData) {
    // We have existing data but it is being replaced by a new, empty dataset.
    // Emit that there is no data to show.
    if (this.regularData.length > 0 && chartData.chartPoints.length === 0) {
      this.hasDataToShow.emit(false);
    }
    this.regularData = [];
    this.incidentData = [];
    this.chartMin = null;
    this.chartMax = null;
    this.chart.data.datasets[REGULAR_DATASET_INDEX].data = this.regularData;
    this.chart.data.datasets[INCIDENT_DATASET_INDEX].data = this.incidentData;
  }

  private updateData(chartData: ChartData) {
    // There's no data to add. Return early.
    if (chartData.chartPoints.length === 0) {
      return;
    }

    // This is the first data we've received. Emit that we have data to show.
    // Note: it's possible that the chart previously had data and was replaced
    // with a new set of data, making this extraneous, but that's OK since this
    // component states it emits *at least once* per state change.
    if (this.regularData.length === 0) {
      this.hasDataToShow.emit(true);
    }

    const regularPoints = chartData.chartPoints;
    const incidentPoints = [];

    this.updateDatasetsForIncidents(regularPoints, incidentPoints);

    this.regularData.push(...regularPoints);
    this.incidentData.push(...incidentPoints);

    this.regularData = addGapsToData(
      this.regularData,
      chartData.chartPoints.length,
      this.maxTimeGapMs
    );
    this.chart.data.datasets[REGULAR_DATASET_INDEX].data =
      this.maybeTransformCategoricalData(this.regularData);
    this.chart.data.datasets[INCIDENT_DATASET_INDEX].data =
      this.maybeTransformCategoricalData(this.incidentData);
  }

  /**
   * For categorical charts, the component is given chart points with a Y value
   * mapping to an enum. Chart.js expects this to be a string (corresponding to
   * the list of strings we give to the Y axis), so we convert when necessary.
   */
  private maybeTransformCategoricalData(data: ChartPoint[]) {
    if (!this.yAxisLabels) {
      return data;
    }
    return data.map((point) => ({
      x: point.x,
      y: this.yAxisLabels.get(point.y),
    }));
  }

  /**
   * Modifies the 'regular' and 'incident' datasets to account for an incident.
   * During an incident, values in the regular dataset are set to null so that
   * the data is still continuous but the values don't show as part of that
   * dataset. The incident dataset is set to contain all the points that
   * occurred during the incident (and up to one before and one after if we're
   * unsure when the incident threshold was crossed).
   */
  private updateDatasetsForIncidents(
    regularPoints: ChartPoint[],
    incidentPoints: ChartPoint[]
  ) {
    if (!this.incidentTimeSec) {
      return;
    }
    const regularPointsTimesSec = regularPoints.map((point) => point.x / 1e3);
    const incidentStartIndexPessimistic = getFirstIncidentIndexPessimistic(
      regularPointsTimesSec,
      this.incidentTimeSec,
      this.resolutionTimeSec
    );
    const incidentEndIndexPessimistic = getLastIncidentIndexPessimistic(
      regularPointsTimesSec,
      this.incidentTimeSec,
      this.resolutionTimeSec
    );
    for (
      let i = incidentStartIndexPessimistic;
      i <= incidentEndIndexPessimistic;
      i++
    ) {
      incidentPoints.push({...regularPoints[i]});
      if (
        i !== incidentStartIndexPessimistic &&
        i !== incidentEndIndexPessimistic
      ) {
        regularPoints[i].y = null;
      }
    }
  }

  /**
   * To show the min and max thresholds, we render lines that contain two
   * points with the same Y value. One has an X value from the earliest data
   * point in the chart and the other has an X value from the newest data point.
   */
  private updateMinAndMaxThresholds() {
    if (this.regularData.length === 0) {
      return;
    }
    const mostRecentPointTime = this.regularData[this.regularData.length - 1].x;
    const oldestPointTime = this.regularData[0].x;
    if (this.minValueThreshold != null) {
      this.chart.data.datasets[MIN_THRESHOLD_DATASET_INDEX].data = [
        {x: mostRecentPointTime, y: this.minValueThreshold},
        {x: oldestPointTime, y: this.minValueThreshold},
      ];
    }
    if (this.maxValueThreshold != null) {
      this.chart.data.datasets[MAX_THRESHOLD_DATASET_INDEX].data = [
        {x: mostRecentPointTime, y: this.maxValueThreshold},
        {x: oldestPointTime, y: this.maxValueThreshold},
      ];
    }
  }

  /**
   * When explicit time bounds are given as Inputs, we respect those; otherwise,
   * we let the chart automatically set its bounds.
   */
  private updateXAxisBounds(chartData: ChartData) {
    let minTimeMs;
    if (chartData.timeRangeStartSec) {
      minTimeMs = chartData.timeRangeStartSec * 1e3;
      this.chart.options.scales.xAxes[0].ticks.min = minTimeMs;
    } else {
      minTimeMs = this.regularData[0].x;
    }

    let maxTimeMs;
    if (chartData.timeRangeEndSec) {
      maxTimeMs = chartData.timeRangeEndSec * 1e3;
      this.chart.options.scales.xAxes[0].ticks.max = maxTimeMs;
    } else {
      maxTimeMs = this.regularData[this.regularData.length - 1].x;
    }

    this.chart.options.scales.xAxes[0].time = {
      unit: getXAxisUnitForTimeRange(minTimeMs, maxTimeMs),
    };
  }

  private updateYAxisBounds(chartData: ChartData) {
    // There's no new data, thus it's not possible to calculate new min/max
    // values. Return early.
    if (chartData.chartPoints.length === 0) {
      return;
    }

    this.updateYAxisMin(chartData);
    this.updateYAxisMax(chartData);
  }

  private updateYAxisMin(chartData: ChartData) {
    // The caller specified an explicit min or the chart is categorical; don't
    // bother computing suggestions.
    if (this.yAxisMin != undefined || this.yAxisLabels) {
      return;
    }

    const newDataMin = Math.min(
      ...chartData.chartPoints
        .filter((point) => point.y != null)
        .map((point) => point.y)
    );
    if (this.chartMin == null || newDataMin < this.chartMin) {
      this.chartMin = newDataMin;
      // Sets the suggestedMin (rather than min) so that if we happen to get a
      // data point outside the range, it will be shown.
      this.chart.options.scales.yAxes[0].ticks.suggestedMin =
        newDataMin - BOUND_PADDING;
    }
  }

  private updateYAxisMax(chartData: ChartData) {
    // The caller specified an explicit max or the chart is categorical; don't
    // bother computing suggestions.
    if (this.yAxisMax != undefined || this.yAxisLabels) {
      return;
    }

    const newDataMax = Math.max(
      ...chartData.chartPoints
        .filter((point) => point.y != null)
        .map((point) => point.y)
    );
    if (this.chartMax == null || newDataMax > this.chartMax) {
      this.chartMax = newDataMax;
      // Sets the suggestedMax (rather than max) so that if we happen to get a
      // data point outside the range, it will be shown.
      this.chart.options.scales.yAxes[0].ticks.suggestedMax =
        newDataMax + BOUND_PADDING;
    }
  }

  private updatePointStylingForDataDensity() {
    const numDataPoints = this.regularData.length;
    if (numDataPoints === 0) {
      return;
    }
    const regularDataset = this.chart.data.datasets[REGULAR_DATASET_INDEX];
    const chartIncidentDataset =
      this.chart.data.datasets[INCIDENT_DATASET_INDEX];
    if (numDataPoints < 10) {
      // Few data points - use a border and a fill.
      regularDataset.pointBackgroundColor = GOOGLE_WHITE;
      chartIncidentDataset.pointBackgroundColor = GOOGLE_WHITE;
      regularDataset.pointBorderWidth = 2;
      chartIncidentDataset.pointBorderWidth = 2;
      regularDataset.pointRadius = 3;
      chartIncidentDataset.pointRadius = 3;
    } else if (numDataPoints < 50 || this.forceShowPoints) {
      // Medium number of data points (or points were explicitly requested by
      // the user) - use only a fill color.
      regularDataset.pointBackgroundColor = GOOGLE_BLUE_500;
      chartIncidentDataset.pointBackgroundColor = GOOGLE_RED_400;
      regularDataset.pointBorderWidth = 0;
      chartIncidentDataset.pointBorderWidth = 0;
      regularDataset.pointRadius = 2;
      chartIncidentDataset.pointRadius = 2;
    } else {
      // Lots of points - hide individual data points.
      regularDataset.pointBorderWidth = 0;
      chartIncidentDataset.pointBorderWidth = 0;
      regularDataset.pointRadius = 0;
      chartIncidentDataset.pointRadius = 0;
    }
  }

  private initChart() {
    this.chart = new Chart(this.chartRef.nativeElement, {
      type: 'line',
      data: {
        datasets: [
          // "Regular" data.
          {
            data: [],
            // Color of the fill area under the line.
            backgroundColor: GOOGLE_BLUE_100,
            // Color of the line.
            borderColor: GOOGLE_BLUE_500,
            // Fill the area under the line.
            fill: this.fillMode,
            // Draw straight lines; no curves.
            lineTension: 0,
            // When NaN is found, do not connect points before and after.
            spanGaps: false,
            // Disable miter joints, which historically made users think there
            // were "spikes" in dense data.
            borderJoinStyle: 'round',
          },
          // "Incident" data.
          {
            data: [],
            // Color of the fill area under the line.
            backgroundColor: GOOGLE_RED_100,
            // Color of the line.
            borderColor: GOOGLE_RED_400,
            // Fill the area under the line.
            fill: this.fillMode,
            // Draw straight lines; no curves.
            lineTension: 0,
            // When NaN is found, do not connect points before and after.
            spanGaps: false,
            // Disable miter joints, which historically made users think there
            // were "spikes" in dense data.
            borderJoinStyle: 'round',
          },
          // A non-interactive, horizontal dashed line used to indicate a
          // minimum value threshold.
          {
            data: [],
            ...THRESHOLD_LINE_OPTIONS,
          },
          // A non-interactive, horizontal dashed line used to indicate a
          // maximum value threshold.
          {
            data: [],
            ...THRESHOLD_LINE_OPTIONS,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              type: 'time',
            },
          ],
          yAxes: [
            {
              type: this.yAxisLabels ? 'category' : undefined,
              ticks: {
                suggestedMin: this.yAxisMin,
                suggestedMax: this.yAxisMax,
              },
            },
          ],
        },
      },
    });
    // We can't conditionally set the Y axis's type here, since it's not
    // possible to change after it is created.
    if (this.yAxisLabels) {
      this.chart.data.yLabels = this.getYLabelStrings();
      this.chart.data.datasets[REGULAR_DATASET_INDEX].steppedLine = 'before';
      this.chart.data.datasets[INCIDENT_DATASET_INDEX].steppedLine = 'before';
    }
    // This is needed because we have an impossible dependency chain: adding a
    // dynamic child component must be done in AfterViewInit (since we need
    // to reference the chart container's DOM). However, the DOM modification
    // check is done before AfterViewInit. Thus, we need to tell Angular we're
    // making changes so it doesn't blow up in its next rendering cycle. More
    // details:
    // https://indepth.dev/everything-you-need-to-know-about-the-expressionchangedafterithasbeencheckederror-error/
    this.changeDetector.detectChanges();
  }

  private getYLabelStrings(): string[] | null {
    if (!this.yAxisLabels) {
      return null;
    }
    return [...this.yAxisLabels.keys()]
      .sort((a, b) => a - b)
      .map((key) => this.yAxisLabels.get(key));
  }
}

function addGapsToData(
  data: ChartPoint[],
  newDataCount: number,
  maxTimeGapMs: number
): ChartPoint[] {
  const chartPoints = [];
  for (let i = 0; i < newDataCount; i++) {
    const currPoint = data[i];
    chartPoints.push(currPoint);
    // If there is a significant gap between data points, add an "empty" point
    // so chart.js will show a gap in the data.
    const nextPointInFuture = i < data.length - 1 ? data[i + 1] : null;
    if (nextPointInFuture && nextPointInFuture.x - currPoint.x > maxTimeGapMs) {
      chartPoints.push(
        getEmptyChartPointForTimeMs(currPoint.x - CHART_GAP_MS_OFFSET)
      );
    }
  }
  const alreadyProcessedData = data.slice(newDataCount);
  return [...chartPoints, ...alreadyProcessedData];
}

/**
 * Chart.js does not automatically detect "gaps" between data points. It uses
 * NaN as a sentinel value to know when there is a gap between points.
 */
function getEmptyChartPointForTimeMs(timeMs: number | null): ChartPoint {
  const time = timeMs || new Date().getTime();
  return {
    x: time,
    y: NaN,
  };
}

function getXAxisUnitForTimeRange(
  startTimeMs: number,
  endTimeMs: number
): string {
  const timeRangeSec = (endTimeMs - startTimeMs) / 1e3;
  if (timeRangeSec > SEC_IN_YEAR * 3) {
    return 'year';
  } else if (timeRangeSec > SEC_IN_MONTH * 3) {
    return 'month';
  } else if (timeRangeSec > SEC_IN_WEEK * 3) {
    return 'week';
  } else if (timeRangeSec > SEC_IN_DAY * 3) {
    return 'day';
  } else if (timeRangeSec > SEC_IN_HOUR * 3) {
    return 'hour';
  }
  return 'minute';
}
