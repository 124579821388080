import {MatDialog} from '@angular/material/dialog';
import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import {Asset, TripIdentifier} from 'src/app/jspb/entity_pb';
import {EndpointsService} from 'src/app/services/endpoints-service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'trip-asset-list',
  templateUrl: './trip-asset-list.component.html',
  styleUrls: ['./trip-asset-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripAssetListComponent {
  @Input() assets: Asset[] | null;
  @Input() tripIdentifier: TripIdentifier;
  @Output() reloadAssets: EventEmitter<void> = new EventEmitter();

  constructor(
    private dialog: MatDialog,
    private endpointsService: EndpointsService,
    private snackBar: MatSnackBar
  ) {}
}
