<div class="flow-view" *ngIf="pairModel.selectedFlow$ | async; let flow">
  <!-- While it's more semantically correct to use a <form> here, we do not
    since many of the default behaviors around validation and submissions
    do not play nicely with our setup. Specifically, we do *not* want pressing
    Enter to submit the form (since it is automatically "pressed" by most
    barcode scanners); we also need to use async validators, which further
    complicates "vanilla" form behavior -->
  <div class="flow-container">
    <h2 class="flow-name">{{ flow.getFlowName() }}</h2>
    <div
      class="flow-field-container"
      *ngFor="let field of flow.getFieldsList(); index as i"
    >
      <flow-field
        class="flow-field"
        [showCurrentFieldIndicator]="(currentFieldIndex$ | async) === i"
        [field]="field"
        (focusin)="currentFieldIndex$.next(i)"
        (focusNextField)="focusNextField(i)"
      >
      </flow-field>
    </div>
    <button
      #submitButton
      mat-flat-button
      type="submit"
      color="primary"
      class="submit-button"
      i18n="Label for a button to submit a form"
      [disabled]="loading$ | async"
      (click)="submit()"
    >
      Submit
    </button>
  </div>
</div>

<ng-template #pageName>
  <ng-container
    i18n="
      Title of the page (also used in the navigation bar) which allows a user to
      pair (i.e. associate) a tracking device with an asset (e.g. a container of
      goods)
    "
  >
    Pair
  </ng-container>
</ng-template>

<ng-template #successSnackBarTemplate>
  <div class="pairing-highlight-bar pairing-success"></div>
  <div class="pairing-snackbar-content">
    <mat-icon class="pairing-snackbar-icon success-icon"
      >check_circle_filled</mat-icon
    >
    <span
      i18n="
        Message shown in a snack bar when a request to pair (i.e. associate
        with) a tracking device with an asset (e.g. a container of goods) has
        been successfully sent
      "
    >
      Successfully sent pairing request
    </span>
  </div>
</ng-template>

<ng-template #failureSnackBarTemplate>
  <div class="pairing-highlight-bar pairing-failure"></div>
  <div class="pairing-snackbar-content">
    <mat-icon
      fontSet="google-material-icons-filled"
      class="pairing-snackbar-icon failure-icon"
    >
      cancel
    </mat-icon>
    <span
      i18n="
        Message shown in a snack bar when a tracking device could not be paired
        (i.e. associated with) with an asset (e.g. a container of goods), due to
        a server error
      "
    >
      Failed to update pairing
    </span>
  </div>
</ng-template>
