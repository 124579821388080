import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  AfterViewInit,
  OnDestroy,
  TemplateRef,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {environment} from 'src/environments/environment';
import {Barcode, BarcodePicker, configure, ScanSettings} from 'scandit-sdk';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BannerService} from '../../banner/banner-service';

export interface CodeScannerDialogData {
  scanTargetText: string;
}

const SCANDIT_SDK_ENGINE_LOCATION =
  'https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/';

// Per https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
const DENIED_CAMERA_ACCESS_ERROR = 'NotAllowedError';
const NO_CAMERA_AVAILABLE_ERROR = 'NoCameraAvailableError';
// Define the ASCII characters to filter out.
// ASCII 29 represents non-printable character group separators.
// This is a short-term solution until we implement our own parser.
const unwantedAscii = [29];

@Component({
  selector: 'code-scanner-dialog',
  templateUrl: './code-scanner-dialog.component.html',
  styleUrls: ['./code-scanner-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeScannerDialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild('noCamera')
  private noCameraTemplate: TemplateRef<HTMLElement>;
  @ViewChild('cameraPermissionDenied')
  private cameraPermissionDeniedTemplate: TemplateRef<HTMLElement>;
  @ViewChild('barcodePicker')
  private barcodePickerElement: ElementRef<HTMLElement>;
  private barcodePicker: BarcodePicker | null = null;
  private finishedInit: Promise<void>;

  constructor(
    private bannerService: BannerService,
    @Inject(MAT_DIALOG_DATA) public data: CodeScannerDialogData,
    public dialogRef: MatDialogRef<CodeScannerDialogComponent>
  ) {}

  async ngAfterViewInit() {
    this.finishedInit = configure(environment.scanditApiKey, {
      engineLocation: SCANDIT_SDK_ENGINE_LOCATION,
    })
      .then(() => {
        return BarcodePicker.create(this.barcodePickerElement.nativeElement, {
          guiStyle: BarcodePicker.GuiStyle.VIEWFINDER,
          scanSettings: new ScanSettings({
            enabledSymbologies: [
              Barcode.Symbology.DATA_MATRIX,
              Barcode.Symbology.MICRO_QR,
              Barcode.Symbology.QR,
              Barcode.Symbology.CODE128,
              Barcode.Symbology.CODE32,
              Barcode.Symbology.CODE39,
              Barcode.Symbology.CODE93,
              Barcode.Symbology.INTERLEAVED_2_OF_5,
              Barcode.Symbology.EAN13,
              Barcode.Symbology.EAN8,
              Barcode.Symbology.UPCA,
              Barcode.Symbology.UPCE,
              Barcode.Symbology.GS1_DATABAR,
              Barcode.Symbology.GS1_DATABAR_LIMITED,
              Barcode.Symbology.GS1_DATABAR_EXPANDED,
            ],
            // Search area is a rectangle stretching the middle 30% of the height and 100% of the width.
            searchArea: {x: 0, y: 0.35, width: 1.0, height: 0.3},
          }),
          hideLogo: true,
        });
      })
      .then((barcodePicker) => {
        this.barcodePicker = barcodePicker.on('scan', (scanResult) => {
          // Filter out unwanted ASCII characters
          const filteredArray = scanResult.barcodes[0].rawData.filter(
            (char) => !unwantedAscii.includes(char)
          );
          // Convert the filtered Uint8Array to a string and return
          return this.closeDialogWithResult(
            String.fromCharCode.apply(null, filteredArray)
          );
        });
      })
      .catch((error) => {
        if (error.name && error.name === DENIED_CAMERA_ACCESS_ERROR) {
          this.bannerService.show(this.cameraPermissionDeniedTemplate);
        } else if (error.name && error.name === NO_CAMERA_AVAILABLE_ERROR) {
          this.bannerService.show(this.noCameraTemplate);
        }
        this.closeDialogWithResult();
      });
  }

  async ngOnDestroy() {
    this.finishedInit.then(() => {
      if (this.barcodePicker) {
        this.barcodePicker.destroy(true);
      }
    });
  }

  closeDialogWithResult(scannedValue: string | null = null) {
    this.dialogRef.close(scannedValue);
  }
}
