import {Component, OnInit, OnDestroy} from '@angular/core';
import {AuthService} from '../services/auth-service';
import {filter, map, switchMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {PersistentParamsService} from '../shared/persistent-params/persistent-params.service';
import {EndpointsService} from '../services/endpoints-service';
import {GetUserByEmailResponse} from '../jspb/org_api_pb';

// https://docs.google.com/spreadsheets/d/1sxZve-6MHmkCp-Y2wwVvZ1Bv7T5ufWzSADhCwSBsNIg/edit?usp=sharing&resourcekey=0-lwVlyr_ziT7WNUHclchx9Q
const OLD_DASHBOARD_ALLOWED_ORG_NAMES = new Set(['HCL', 'HCL AU', 'Suzuken']);

/**
 * This component is used as a wrapper for all login-protected routes. It
 * renders the page chrome: navbar, toolbar, etc.
 */
@Component({
  selector: 'page-wrapper',
  templateUrl: './page-wrapper.component.html',
  styleUrls: ['./page-wrapper.component.scss'],
})
export class PageWrapperComponent implements OnDestroy, OnInit {
  private subscriptions: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private endpointsService: EndpointsService,
    private persistentParamsService: PersistentParamsService,
    private router: Router
  ) {}

  ngOnInit() {
    // Redirect the user to the login page whenever their state changes to 'not
    // logged in.' This covers logging out in the current tab, logging out in
    // another tab, and session expiration.
    this.subscriptions.add(
      this.authService.loggedIn$
        .pipe(filter((loggedIn) => loggedIn === false))
        .subscribe({
          next: () =>
            this.router.navigateByUrl(
              this.persistentParamsService.updateUrl('login')
            ),
        })
    );
    // Set up a listener to redirect all non-allowlisted orgs to Summit. Do *not*
    // redirect legacy.chorussystems.net.
    if (
      window.location.hostname === 'chorussystems.net' ||
      window.location.hostname === 'dev.chorussystems.net'
    ) {
      this.addRedirectToSummitSubscription();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private addRedirectToSummitSubscription() {
    this.subscriptions.add(
      this.authService.loggedIn$
        .pipe(
          filter((loggedIn) => loggedIn === true),
          switchMap(() => this.authService.userEmail$),
          switchMap((userEmail) =>
            this.endpointsService.getUserByEmail(userEmail)
          ),
          map((response: GetUserByEmailResponse) =>
            response.getUser().getOrgName()
          ),
          filter((orgName) => !OLD_DASHBOARD_ALLOWED_ORG_NAMES.has(orgName))
        )
        .subscribe({
          next: () => {
            window.location.href = 'https://summit.chorussystems.net';
          },
        })
    );
  }
}
