<mat-progress-bar
  mode="indeterminate"
  class="progress-bar"
  *ngIf="loading$ | async"
></mat-progress-bar>

<div class="associated-asset-list">
  <base-associated-entity-list
    [entities]="assets"
    [entityTemplate]="assetEntry"
    [noEntitiesTemplate]="noAssets"
  ></base-associated-entity-list>
</div>

<ng-template #assetEntry let-asset>
  <asset-list-item [asset]="asset"></asset-list-item>
</ng-template>

<ng-template #noAssets>
  <div
    class="no-associated-assets"
    i18n="
      Empty state message shown when there are no assets (e.g., containers full
      of goods) that have been paired with the current shipment
    "
  >
    No associated assets
  </div>
</ng-template>
