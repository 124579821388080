import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  OnChanges,
  OnInit,
} from '@angular/core';
import {Trip, TripIdentifier} from 'src/app/jspb/entity_pb';
import {TimeZoneService} from '../../services/time-zone-service';
import {Observable, ReplaySubject, concat, of} from 'rxjs';
import {EndpointsService} from 'src/app/services/endpoints-service';
import {map, switchMap} from 'rxjs/operators';
import {Timestamp} from 'src/app/jspb/google/protobuf/timestamp_pb';

@Component({
  selector: 'trip-detail',
  templateUrl: './trip-detail.component.html',
  styleUrls: ['./trip-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripDetailComponent implements OnChanges, OnInit {
  TripStage = Trip.TripStage;
  @Input() trip: Trip;
  @Output() reloadTrip: EventEmitter<void> = new EventEmitter();

  tripIdentifier: TripIdentifier;
  etaMs$: Observable<number>;
  private tripId$: ReplaySubject<string> = new ReplaySubject(1);

  constructor(
    public endpointsService: EndpointsService,
    public timeZoneService: TimeZoneService
  ) {}

  ngOnInit() {
    this.etaMs$ = this.tripId$.pipe(
      switchMap((tripId: string) =>
        // We use this weird concat trick so that we *immediately* assume the ETA
        // is not present whenever we switch trips. Due to the way this component
        // is rendered, it's not re-created and destroyed every time a trip is
        // selected. Thus, to avoid a "stale" ETA being shown when looking at a trip [with
        // an ETA] and then clicking on a trip without one.
        concat(of(null), this.endpointsService.getTripEta(tripId))
      ),
      map(
        (timestamp: Timestamp | null) =>
          timestamp && timestamp.getSeconds() * 1e3
      )
    );
  }

  ngOnChanges() {
    this.tripIdentifier = new TripIdentifier();
    this.tripIdentifier.setScoutId(this.trip.getTripId());
    this.tripIdentifier.setCustomerId(this.trip.getCustomerId());
    this.tripId$.next(this.trip.getTripId());
  }
}
