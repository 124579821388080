/**
 * @fileoverview gRPC-Web generated client stub for com.google.scout.chorus.proto
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as org_api_pb from './org_api_pb';
import * as entity_pb from './entity_pb';
import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';


export class OrganizationsClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListOrganizations = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Organizations/ListOrganizations',
    grpcWeb.MethodType.UNARY,
    org_api_pb.ListOrganizationsRequest,
    org_api_pb.ListOrganizationsResponse,
    (request: org_api_pb.ListOrganizationsRequest) => {
      return request.serializeBinary();
    },
    org_api_pb.ListOrganizationsResponse.deserializeBinary
  );

  listOrganizations(
    request: org_api_pb.ListOrganizationsRequest,
    metadata: grpcWeb.Metadata | null): Promise<org_api_pb.ListOrganizationsResponse>;

  listOrganizations(
    request: org_api_pb.ListOrganizationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: org_api_pb.ListOrganizationsResponse) => void): grpcWeb.ClientReadableStream<org_api_pb.ListOrganizationsResponse>;

  listOrganizations(
    request: org_api_pb.ListOrganizationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: org_api_pb.ListOrganizationsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Organizations/ListOrganizations',
        request,
        metadata || {},
        this.methodDescriptorListOrganizations,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Organizations/ListOrganizations',
    request,
    metadata || {},
    this.methodDescriptorListOrganizations);
  }

  methodDescriptorListDevices = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Organizations/ListDevices',
    grpcWeb.MethodType.UNARY,
    org_api_pb.ListDevicesRequest,
    org_api_pb.ListDevicesResponse,
    (request: org_api_pb.ListDevicesRequest) => {
      return request.serializeBinary();
    },
    org_api_pb.ListDevicesResponse.deserializeBinary
  );

  listDevices(
    request: org_api_pb.ListDevicesRequest,
    metadata: grpcWeb.Metadata | null): Promise<org_api_pb.ListDevicesResponse>;

  listDevices(
    request: org_api_pb.ListDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: org_api_pb.ListDevicesResponse) => void): grpcWeb.ClientReadableStream<org_api_pb.ListDevicesResponse>;

  listDevices(
    request: org_api_pb.ListDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: org_api_pb.ListDevicesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Organizations/ListDevices',
        request,
        metadata || {},
        this.methodDescriptorListDevices,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Organizations/ListDevices',
    request,
    metadata || {},
    this.methodDescriptorListDevices);
  }

  methodDescriptorUpdateOrganization = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Organizations/UpdateOrganization',
    grpcWeb.MethodType.UNARY,
    org_api_pb.UpdateOrganizationRequest,
    org_api_pb.UpdateOrganizationResponse,
    (request: org_api_pb.UpdateOrganizationRequest) => {
      return request.serializeBinary();
    },
    org_api_pb.UpdateOrganizationResponse.deserializeBinary
  );

  updateOrganization(
    request: org_api_pb.UpdateOrganizationRequest,
    metadata: grpcWeb.Metadata | null): Promise<org_api_pb.UpdateOrganizationResponse>;

  updateOrganization(
    request: org_api_pb.UpdateOrganizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: org_api_pb.UpdateOrganizationResponse) => void): grpcWeb.ClientReadableStream<org_api_pb.UpdateOrganizationResponse>;

  updateOrganization(
    request: org_api_pb.UpdateOrganizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: org_api_pb.UpdateOrganizationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Organizations/UpdateOrganization',
        request,
        metadata || {},
        this.methodDescriptorUpdateOrganization,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Organizations/UpdateOrganization',
    request,
    metadata || {},
    this.methodDescriptorUpdateOrganization);
  }

  methodDescriptorUpdateDevice = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Organizations/UpdateDevice',
    grpcWeb.MethodType.UNARY,
    org_api_pb.UpdateDeviceRequest,
    org_api_pb.UpdateDeviceResponse,
    (request: org_api_pb.UpdateDeviceRequest) => {
      return request.serializeBinary();
    },
    org_api_pb.UpdateDeviceResponse.deserializeBinary
  );

  updateDevice(
    request: org_api_pb.UpdateDeviceRequest,
    metadata: grpcWeb.Metadata | null): Promise<org_api_pb.UpdateDeviceResponse>;

  updateDevice(
    request: org_api_pb.UpdateDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: org_api_pb.UpdateDeviceResponse) => void): grpcWeb.ClientReadableStream<org_api_pb.UpdateDeviceResponse>;

  updateDevice(
    request: org_api_pb.UpdateDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: org_api_pb.UpdateDeviceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Organizations/UpdateDevice',
        request,
        metadata || {},
        this.methodDescriptorUpdateDevice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Organizations/UpdateDevice',
    request,
    metadata || {},
    this.methodDescriptorUpdateDevice);
  }

  methodDescriptorCreateUser = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Organizations/CreateUser',
    grpcWeb.MethodType.UNARY,
    org_api_pb.CreateUserRequest,
    entity_pb.User,
    (request: org_api_pb.CreateUserRequest) => {
      return request.serializeBinary();
    },
    entity_pb.User.deserializeBinary
  );

  createUser(
    request: org_api_pb.CreateUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<entity_pb.User>;

  createUser(
    request: org_api_pb.CreateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: entity_pb.User) => void): grpcWeb.ClientReadableStream<entity_pb.User>;

  createUser(
    request: org_api_pb.CreateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: entity_pb.User) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Organizations/CreateUser',
        request,
        metadata || {},
        this.methodDescriptorCreateUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Organizations/CreateUser',
    request,
    metadata || {},
    this.methodDescriptorCreateUser);
  }

  methodDescriptorListUsers = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Organizations/ListUsers',
    grpcWeb.MethodType.UNARY,
    org_api_pb.ListUsersRequest,
    org_api_pb.ListUsersResponse,
    (request: org_api_pb.ListUsersRequest) => {
      return request.serializeBinary();
    },
    org_api_pb.ListUsersResponse.deserializeBinary
  );

  listUsers(
    request: org_api_pb.ListUsersRequest,
    metadata: grpcWeb.Metadata | null): Promise<org_api_pb.ListUsersResponse>;

  listUsers(
    request: org_api_pb.ListUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: org_api_pb.ListUsersResponse) => void): grpcWeb.ClientReadableStream<org_api_pb.ListUsersResponse>;

  listUsers(
    request: org_api_pb.ListUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: org_api_pb.ListUsersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Organizations/ListUsers',
        request,
        metadata || {},
        this.methodDescriptorListUsers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Organizations/ListUsers',
    request,
    metadata || {},
    this.methodDescriptorListUsers);
  }

  methodDescriptorUpdateUser = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Organizations/UpdateUser',
    grpcWeb.MethodType.UNARY,
    org_api_pb.UpdateUserRequest,
    org_api_pb.UpdateUserResponse,
    (request: org_api_pb.UpdateUserRequest) => {
      return request.serializeBinary();
    },
    org_api_pb.UpdateUserResponse.deserializeBinary
  );

  updateUser(
    request: org_api_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<org_api_pb.UpdateUserResponse>;

  updateUser(
    request: org_api_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: org_api_pb.UpdateUserResponse) => void): grpcWeb.ClientReadableStream<org_api_pb.UpdateUserResponse>;

  updateUser(
    request: org_api_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: org_api_pb.UpdateUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Organizations/UpdateUser',
        request,
        metadata || {},
        this.methodDescriptorUpdateUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Organizations/UpdateUser',
    request,
    metadata || {},
    this.methodDescriptorUpdateUser);
  }

  methodDescriptorUpdateUserByEmail = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Organizations/UpdateUserByEmail',
    grpcWeb.MethodType.UNARY,
    org_api_pb.UpdateUserByEmailRequest,
    entity_pb.User,
    (request: org_api_pb.UpdateUserByEmailRequest) => {
      return request.serializeBinary();
    },
    entity_pb.User.deserializeBinary
  );

  updateUserByEmail(
    request: org_api_pb.UpdateUserByEmailRequest,
    metadata: grpcWeb.Metadata | null): Promise<entity_pb.User>;

  updateUserByEmail(
    request: org_api_pb.UpdateUserByEmailRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: entity_pb.User) => void): grpcWeb.ClientReadableStream<entity_pb.User>;

  updateUserByEmail(
    request: org_api_pb.UpdateUserByEmailRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: entity_pb.User) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Organizations/UpdateUserByEmail',
        request,
        metadata || {},
        this.methodDescriptorUpdateUserByEmail,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Organizations/UpdateUserByEmail',
    request,
    metadata || {},
    this.methodDescriptorUpdateUserByEmail);
  }

  methodDescriptorDeleteUser = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Organizations/DeleteUser',
    grpcWeb.MethodType.UNARY,
    org_api_pb.DeleteUserRequest,
    google_protobuf_empty_pb.Empty,
    (request: org_api_pb.DeleteUserRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  deleteUser(
    request: org_api_pb.DeleteUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  deleteUser(
    request: org_api_pb.DeleteUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  deleteUser(
    request: org_api_pb.DeleteUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Organizations/DeleteUser',
        request,
        metadata || {},
        this.methodDescriptorDeleteUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Organizations/DeleteUser',
    request,
    metadata || {},
    this.methodDescriptorDeleteUser);
  }

  methodDescriptorDeleteOrganization = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Organizations/DeleteOrganization',
    grpcWeb.MethodType.UNARY,
    org_api_pb.DeleteOrganizationRequest,
    google_protobuf_empty_pb.Empty,
    (request: org_api_pb.DeleteOrganizationRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  deleteOrganization(
    request: org_api_pb.DeleteOrganizationRequest,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  deleteOrganization(
    request: org_api_pb.DeleteOrganizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  deleteOrganization(
    request: org_api_pb.DeleteOrganizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Organizations/DeleteOrganization',
        request,
        metadata || {},
        this.methodDescriptorDeleteOrganization,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Organizations/DeleteOrganization',
    request,
    metadata || {},
    this.methodDescriptorDeleteOrganization);
  }

  methodDescriptorDeleteDevice = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Organizations/DeleteDevice',
    grpcWeb.MethodType.UNARY,
    org_api_pb.DeleteDeviceRequest,
    google_protobuf_empty_pb.Empty,
    (request: org_api_pb.DeleteDeviceRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  deleteDevice(
    request: org_api_pb.DeleteDeviceRequest,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  deleteDevice(
    request: org_api_pb.DeleteDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  deleteDevice(
    request: org_api_pb.DeleteDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Organizations/DeleteDevice',
        request,
        metadata || {},
        this.methodDescriptorDeleteDevice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Organizations/DeleteDevice',
    request,
    metadata || {},
    this.methodDescriptorDeleteDevice);
  }

  methodDescriptorAddDeviceToOrg = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Organizations/AddDeviceToOrg',
    grpcWeb.MethodType.UNARY,
    org_api_pb.AddDeviceToOrgRequest,
    google_protobuf_empty_pb.Empty,
    (request: org_api_pb.AddDeviceToOrgRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  addDeviceToOrg(
    request: org_api_pb.AddDeviceToOrgRequest,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  addDeviceToOrg(
    request: org_api_pb.AddDeviceToOrgRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  addDeviceToOrg(
    request: org_api_pb.AddDeviceToOrgRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Organizations/AddDeviceToOrg',
        request,
        metadata || {},
        this.methodDescriptorAddDeviceToOrg,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Organizations/AddDeviceToOrg',
    request,
    metadata || {},
    this.methodDescriptorAddDeviceToOrg);
  }

  methodDescriptorDeprovisionDeviceBatch = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Organizations/DeprovisionDeviceBatch',
    grpcWeb.MethodType.UNARY,
    org_api_pb.DeprovisionDeviceBatchRequest,
    org_api_pb.DeprovisionDeviceBatchResponse,
    (request: org_api_pb.DeprovisionDeviceBatchRequest) => {
      return request.serializeBinary();
    },
    org_api_pb.DeprovisionDeviceBatchResponse.deserializeBinary
  );

  deprovisionDeviceBatch(
    request: org_api_pb.DeprovisionDeviceBatchRequest,
    metadata: grpcWeb.Metadata | null): Promise<org_api_pb.DeprovisionDeviceBatchResponse>;

  deprovisionDeviceBatch(
    request: org_api_pb.DeprovisionDeviceBatchRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: org_api_pb.DeprovisionDeviceBatchResponse) => void): grpcWeb.ClientReadableStream<org_api_pb.DeprovisionDeviceBatchResponse>;

  deprovisionDeviceBatch(
    request: org_api_pb.DeprovisionDeviceBatchRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: org_api_pb.DeprovisionDeviceBatchResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Organizations/DeprovisionDeviceBatch',
        request,
        metadata || {},
        this.methodDescriptorDeprovisionDeviceBatch,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Organizations/DeprovisionDeviceBatch',
    request,
    metadata || {},
    this.methodDescriptorDeprovisionDeviceBatch);
  }

  methodDescriptorAddOrgToOrg = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Organizations/AddOrgToOrg',
    grpcWeb.MethodType.UNARY,
    org_api_pb.AddOrgToOrgRequest,
    google_protobuf_empty_pb.Empty,
    (request: org_api_pb.AddOrgToOrgRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  addOrgToOrg(
    request: org_api_pb.AddOrgToOrgRequest,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  addOrgToOrg(
    request: org_api_pb.AddOrgToOrgRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  addOrgToOrg(
    request: org_api_pb.AddOrgToOrgRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Organizations/AddOrgToOrg',
        request,
        metadata || {},
        this.methodDescriptorAddOrgToOrg,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Organizations/AddOrgToOrg',
    request,
    metadata || {},
    this.methodDescriptorAddOrgToOrg);
  }

  methodDescriptorRemoveOrgFromOrg = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Organizations/RemoveOrgFromOrg',
    grpcWeb.MethodType.UNARY,
    org_api_pb.RemoveOrgFromOrgRequest,
    google_protobuf_empty_pb.Empty,
    (request: org_api_pb.RemoveOrgFromOrgRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  removeOrgFromOrg(
    request: org_api_pb.RemoveOrgFromOrgRequest,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  removeOrgFromOrg(
    request: org_api_pb.RemoveOrgFromOrgRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  removeOrgFromOrg(
    request: org_api_pb.RemoveOrgFromOrgRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Organizations/RemoveOrgFromOrg',
        request,
        metadata || {},
        this.methodDescriptorRemoveOrgFromOrg,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Organizations/RemoveOrgFromOrg',
    request,
    metadata || {},
    this.methodDescriptorRemoveOrgFromOrg);
  }

  methodDescriptorGetUserByEmail = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Organizations/GetUserByEmail',
    grpcWeb.MethodType.UNARY,
    org_api_pb.GetUserByEmailRequest,
    org_api_pb.GetUserByEmailResponse,
    (request: org_api_pb.GetUserByEmailRequest) => {
      return request.serializeBinary();
    },
    org_api_pb.GetUserByEmailResponse.deserializeBinary
  );

  getUserByEmail(
    request: org_api_pb.GetUserByEmailRequest,
    metadata: grpcWeb.Metadata | null): Promise<org_api_pb.GetUserByEmailResponse>;

  getUserByEmail(
    request: org_api_pb.GetUserByEmailRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: org_api_pb.GetUserByEmailResponse) => void): grpcWeb.ClientReadableStream<org_api_pb.GetUserByEmailResponse>;

  getUserByEmail(
    request: org_api_pb.GetUserByEmailRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: org_api_pb.GetUserByEmailResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Organizations/GetUserByEmail',
        request,
        metadata || {},
        this.methodDescriptorGetUserByEmail,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Organizations/GetUserByEmail',
    request,
    metadata || {},
    this.methodDescriptorGetUserByEmail);
  }

  methodDescriptorCountDevices = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Organizations/CountDevices',
    grpcWeb.MethodType.UNARY,
    org_api_pb.ListDevicesRequest,
    org_api_pb.CountDevicesResponse,
    (request: org_api_pb.ListDevicesRequest) => {
      return request.serializeBinary();
    },
    org_api_pb.CountDevicesResponse.deserializeBinary
  );

  countDevices(
    request: org_api_pb.ListDevicesRequest,
    metadata: grpcWeb.Metadata | null): Promise<org_api_pb.CountDevicesResponse>;

  countDevices(
    request: org_api_pb.ListDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: org_api_pb.CountDevicesResponse) => void): grpcWeb.ClientReadableStream<org_api_pb.CountDevicesResponse>;

  countDevices(
    request: org_api_pb.ListDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: org_api_pb.CountDevicesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Organizations/CountDevices',
        request,
        metadata || {},
        this.methodDescriptorCountDevices,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Organizations/CountDevices',
    request,
    metadata || {},
    this.methodDescriptorCountDevices);
  }

  methodDescriptorgetAllOrgToDeviceRelations = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Organizations/getAllOrgToDeviceRelations',
    grpcWeb.MethodType.UNARY,
    org_api_pb.GetAllOrgToDeviceRelationsRequest,
    org_api_pb.GetAllOrgToDeviceRelationsResponse,
    (request: org_api_pb.GetAllOrgToDeviceRelationsRequest) => {
      return request.serializeBinary();
    },
    org_api_pb.GetAllOrgToDeviceRelationsResponse.deserializeBinary
  );

  getAllOrgToDeviceRelations(
    request: org_api_pb.GetAllOrgToDeviceRelationsRequest,
    metadata: grpcWeb.Metadata | null): Promise<org_api_pb.GetAllOrgToDeviceRelationsResponse>;

  getAllOrgToDeviceRelations(
    request: org_api_pb.GetAllOrgToDeviceRelationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: org_api_pb.GetAllOrgToDeviceRelationsResponse) => void): grpcWeb.ClientReadableStream<org_api_pb.GetAllOrgToDeviceRelationsResponse>;

  getAllOrgToDeviceRelations(
    request: org_api_pb.GetAllOrgToDeviceRelationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: org_api_pb.GetAllOrgToDeviceRelationsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Organizations/getAllOrgToDeviceRelations',
        request,
        metadata || {},
        this.methodDescriptorgetAllOrgToDeviceRelations,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Organizations/getAllOrgToDeviceRelations',
    request,
    metadata || {},
    this.methodDescriptorgetAllOrgToDeviceRelations);
  }

  methodDescriptorgetAllOrgToOrgRelations = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Organizations/getAllOrgToOrgRelations',
    grpcWeb.MethodType.UNARY,
    org_api_pb.GetAllOrgToOrgRelationsRequest,
    org_api_pb.GetAllOrgToOrgRelationsResponse,
    (request: org_api_pb.GetAllOrgToOrgRelationsRequest) => {
      return request.serializeBinary();
    },
    org_api_pb.GetAllOrgToOrgRelationsResponse.deserializeBinary
  );

  getAllOrgToOrgRelations(
    request: org_api_pb.GetAllOrgToOrgRelationsRequest,
    metadata: grpcWeb.Metadata | null): Promise<org_api_pb.GetAllOrgToOrgRelationsResponse>;

  getAllOrgToOrgRelations(
    request: org_api_pb.GetAllOrgToOrgRelationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: org_api_pb.GetAllOrgToOrgRelationsResponse) => void): grpcWeb.ClientReadableStream<org_api_pb.GetAllOrgToOrgRelationsResponse>;

  getAllOrgToOrgRelations(
    request: org_api_pb.GetAllOrgToOrgRelationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: org_api_pb.GetAllOrgToOrgRelationsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Organizations/getAllOrgToOrgRelations',
        request,
        metadata || {},
        this.methodDescriptorgetAllOrgToOrgRelations,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Organizations/getAllOrgToOrgRelations',
    request,
    metadata || {},
    this.methodDescriptorgetAllOrgToOrgRelations);
  }

  methodDescriptorGetMapsPeopleApiKey = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Organizations/GetMapsPeopleApiKey',
    grpcWeb.MethodType.UNARY,
    org_api_pb.GetMapsPeopleApiKeyRequest,
    org_api_pb.GetMapsPeopleApiKeyResponse,
    (request: org_api_pb.GetMapsPeopleApiKeyRequest) => {
      return request.serializeBinary();
    },
    org_api_pb.GetMapsPeopleApiKeyResponse.deserializeBinary
  );

  getMapsPeopleApiKey(
    request: org_api_pb.GetMapsPeopleApiKeyRequest,
    metadata: grpcWeb.Metadata | null): Promise<org_api_pb.GetMapsPeopleApiKeyResponse>;

  getMapsPeopleApiKey(
    request: org_api_pb.GetMapsPeopleApiKeyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: org_api_pb.GetMapsPeopleApiKeyResponse) => void): grpcWeb.ClientReadableStream<org_api_pb.GetMapsPeopleApiKeyResponse>;

  getMapsPeopleApiKey(
    request: org_api_pb.GetMapsPeopleApiKeyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: org_api_pb.GetMapsPeopleApiKeyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Organizations/GetMapsPeopleApiKey',
        request,
        metadata || {},
        this.methodDescriptorGetMapsPeopleApiKey,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Organizations/GetMapsPeopleApiKey',
    request,
    metadata || {},
    this.methodDescriptorGetMapsPeopleApiKey);
  }

}

