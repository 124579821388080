// source: alert_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var entity_pb = require('./entity_pb.js');
goog.object.extend(proto, entity_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var device_payload_pb = require('./device_payload_pb.js');
goog.object.extend(proto, device_payload_pb);
var metrics_api_pb = require('./metrics_api_pb.js');
goog.object.extend(proto, metrics_api_pb);
goog.exportSymbol('proto.com.google.scout.chorus.proto.CountAlertsResponse', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.GetAlertContextDataRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.GetAlertContextDataResponse', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ListAlertsForDashboardRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ListAlertsRequest', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.ListAlertsResponse', null, global);
goog.exportSymbol('proto.com.google.scout.chorus.proto.UpdateAlertRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.UpdateAlertRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.UpdateAlertRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.UpdateAlertRequest.displayName = 'proto.com.google.scout.chorus.proto.UpdateAlertRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.ListAlertsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.ListAlertsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.ListAlertsRequest.displayName = 'proto.com.google.scout.chorus.proto.ListAlertsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.ListAlertsForDashboardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.ListAlertsForDashboardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.ListAlertsForDashboardRequest.displayName = 'proto.com.google.scout.chorus.proto.ListAlertsForDashboardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.ListAlertsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.ListAlertsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.ListAlertsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.ListAlertsResponse.displayName = 'proto.com.google.scout.chorus.proto.ListAlertsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.CountAlertsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.CountAlertsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.CountAlertsResponse.displayName = 'proto.com.google.scout.chorus.proto.CountAlertsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.GetAlertContextDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.GetAlertContextDataRequest.displayName = 'proto.com.google.scout.chorus.proto.GetAlertContextDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.GetAlertContextDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.displayName = 'proto.com.google.scout.chorus.proto.GetAlertContextDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData.repeatedFields_, null);
};
goog.inherits(proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData.displayName = 'proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.UpdateAlertRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.UpdateAlertRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.UpdateAlertRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.UpdateAlertRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    alert: (f = msg.getAlert()) && entity_pb.Alert.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.UpdateAlertRequest}
 */
proto.com.google.scout.chorus.proto.UpdateAlertRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.UpdateAlertRequest;
  return proto.com.google.scout.chorus.proto.UpdateAlertRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.UpdateAlertRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.UpdateAlertRequest}
 */
proto.com.google.scout.chorus.proto.UpdateAlertRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new entity_pb.Alert;
      reader.readMessage(value,entity_pb.Alert.deserializeBinaryFromReader);
      msg.setAlert(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.UpdateAlertRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.UpdateAlertRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.UpdateAlertRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.UpdateAlertRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlert();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      entity_pb.Alert.serializeBinaryToWriter
    );
  }
};


/**
 * optional Alert alert = 1;
 * @return {?proto.com.google.scout.chorus.proto.Alert}
 */
proto.com.google.scout.chorus.proto.UpdateAlertRequest.prototype.getAlert = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Alert} */ (
    jspb.Message.getWrapperField(this, entity_pb.Alert, 1));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Alert|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.UpdateAlertRequest} returns this
*/
proto.com.google.scout.chorus.proto.UpdateAlertRequest.prototype.setAlert = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.UpdateAlertRequest} returns this
 */
proto.com.google.scout.chorus.proto.UpdateAlertRequest.prototype.clearAlert = function() {
  return this.setAlert(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.UpdateAlertRequest.prototype.hasAlert = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.ListAlertsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.ListAlertsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.ListAlertsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListAlertsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deviceId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    assetIdentifier: (f = msg.getAssetIdentifier()) && entity_pb.AssetIdentifier.toObject(includeInstance, f),
    tripIdentifier: (f = msg.getTripIdentifier()) && entity_pb.TripIdentifier.toObject(includeInstance, f),
    returnDevices: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    returnAssets: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    returnTrips: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    returnOrgs: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.ListAlertsRequest}
 */
proto.com.google.scout.chorus.proto.ListAlertsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.ListAlertsRequest;
  return proto.com.google.scout.chorus.proto.ListAlertsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.ListAlertsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.ListAlertsRequest}
 */
proto.com.google.scout.chorus.proto.ListAlertsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 4:
      var value = new entity_pb.AssetIdentifier;
      reader.readMessage(value,entity_pb.AssetIdentifier.deserializeBinaryFromReader);
      msg.setAssetIdentifier(value);
      break;
    case 5:
      var value = new entity_pb.TripIdentifier;
      reader.readMessage(value,entity_pb.TripIdentifier.deserializeBinaryFromReader);
      msg.setTripIdentifier(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturnDevices(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturnAssets(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturnTrips(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturnOrgs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.ListAlertsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.ListAlertsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.ListAlertsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListAlertsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAssetIdentifier();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      entity_pb.AssetIdentifier.serializeBinaryToWriter
    );
  }
  f = message.getTripIdentifier();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      entity_pb.TripIdentifier.serializeBinaryToWriter
    );
  }
  f = message.getReturnDevices();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getReturnAssets();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getReturnTrips();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getReturnOrgs();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional uint32 page_size = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.ListAlertsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.ListAlertsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListAlertsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListAlertsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListAlertsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListAlertsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string device_id = 3;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListAlertsRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListAlertsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListAlertsRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AssetIdentifier asset_identifier = 4;
 * @return {?proto.com.google.scout.chorus.proto.AssetIdentifier}
 */
proto.com.google.scout.chorus.proto.ListAlertsRequest.prototype.getAssetIdentifier = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.AssetIdentifier} */ (
    jspb.Message.getWrapperField(this, entity_pb.AssetIdentifier, 4));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.AssetIdentifier|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ListAlertsRequest} returns this
*/
proto.com.google.scout.chorus.proto.ListAlertsRequest.prototype.setAssetIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ListAlertsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListAlertsRequest.prototype.clearAssetIdentifier = function() {
  return this.setAssetIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListAlertsRequest.prototype.hasAssetIdentifier = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional TripIdentifier trip_identifier = 5;
 * @return {?proto.com.google.scout.chorus.proto.TripIdentifier}
 */
proto.com.google.scout.chorus.proto.ListAlertsRequest.prototype.getTripIdentifier = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.TripIdentifier} */ (
    jspb.Message.getWrapperField(this, entity_pb.TripIdentifier, 5));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.TripIdentifier|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.ListAlertsRequest} returns this
*/
proto.com.google.scout.chorus.proto.ListAlertsRequest.prototype.setTripIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.ListAlertsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListAlertsRequest.prototype.clearTripIdentifier = function() {
  return this.setTripIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListAlertsRequest.prototype.hasTripIdentifier = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool return_devices = 7;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListAlertsRequest.prototype.getReturnDevices = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.ListAlertsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListAlertsRequest.prototype.setReturnDevices = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool return_assets = 8;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListAlertsRequest.prototype.getReturnAssets = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.ListAlertsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListAlertsRequest.prototype.setReturnAssets = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool return_trips = 9;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListAlertsRequest.prototype.getReturnTrips = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.ListAlertsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListAlertsRequest.prototype.setReturnTrips = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool return_orgs = 10;
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.ListAlertsRequest.prototype.getReturnOrgs = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.google.scout.chorus.proto.ListAlertsRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListAlertsRequest.prototype.setReturnOrgs = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.ListAlertsForDashboardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.ListAlertsForDashboardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.ListAlertsForDashboardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListAlertsForDashboardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.ListAlertsForDashboardRequest}
 */
proto.com.google.scout.chorus.proto.ListAlertsForDashboardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.ListAlertsForDashboardRequest;
  return proto.com.google.scout.chorus.proto.ListAlertsForDashboardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.ListAlertsForDashboardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.ListAlertsForDashboardRequest}
 */
proto.com.google.scout.chorus.proto.ListAlertsForDashboardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.ListAlertsForDashboardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.ListAlertsForDashboardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.ListAlertsForDashboardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListAlertsForDashboardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.ListAlertsForDashboardRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.ListAlertsForDashboardRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListAlertsForDashboardRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListAlertsForDashboardRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListAlertsForDashboardRequest} returns this
 */
proto.com.google.scout.chorus.proto.ListAlertsForDashboardRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.ListAlertsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.ListAlertsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.ListAlertsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.ListAlertsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListAlertsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertsList: jspb.Message.toObjectList(msg.getAlertsList(),
    entity_pb.Alert.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.ListAlertsResponse}
 */
proto.com.google.scout.chorus.proto.ListAlertsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.ListAlertsResponse;
  return proto.com.google.scout.chorus.proto.ListAlertsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.ListAlertsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.ListAlertsResponse}
 */
proto.com.google.scout.chorus.proto.ListAlertsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new entity_pb.Alert;
      reader.readMessage(value,entity_pb.Alert.deserializeBinaryFromReader);
      msg.addAlerts(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.ListAlertsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.ListAlertsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.ListAlertsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.ListAlertsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      entity_pb.Alert.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated Alert alerts = 1;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Alert>}
 */
proto.com.google.scout.chorus.proto.ListAlertsResponse.prototype.getAlertsList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Alert>} */ (
    jspb.Message.getRepeatedWrapperField(this, entity_pb.Alert, 1));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Alert>} value
 * @return {!proto.com.google.scout.chorus.proto.ListAlertsResponse} returns this
*/
proto.com.google.scout.chorus.proto.ListAlertsResponse.prototype.setAlertsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Alert=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Alert}
 */
proto.com.google.scout.chorus.proto.ListAlertsResponse.prototype.addAlerts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.google.scout.chorus.proto.Alert, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.ListAlertsResponse} returns this
 */
proto.com.google.scout.chorus.proto.ListAlertsResponse.prototype.clearAlertsList = function() {
  return this.setAlertsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.ListAlertsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.ListAlertsResponse} returns this
 */
proto.com.google.scout.chorus.proto.ListAlertsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.CountAlertsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.CountAlertsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.CountAlertsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CountAlertsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertCount: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.CountAlertsResponse}
 */
proto.com.google.scout.chorus.proto.CountAlertsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.CountAlertsResponse;
  return proto.com.google.scout.chorus.proto.CountAlertsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.CountAlertsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.CountAlertsResponse}
 */
proto.com.google.scout.chorus.proto.CountAlertsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAlertCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.CountAlertsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.CountAlertsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.CountAlertsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.CountAlertsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertCount();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 alert_count = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.CountAlertsResponse.prototype.getAlertCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.CountAlertsResponse} returns this
 */
proto.com.google.scout.chorus.proto.CountAlertsResponse.prototype.setAlertCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.GetAlertContextDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.GetAlertContextDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    alertId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.GetAlertContextDataRequest}
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.GetAlertContextDataRequest;
  return proto.com.google.scout.chorus.proto.GetAlertContextDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.GetAlertContextDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.GetAlertContextDataRequest}
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlertId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.GetAlertContextDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.GetAlertContextDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAlertId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.google.scout.chorus.proto.GetAlertContextDataRequest} returns this
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.GetAlertContextDataRequest} returns this
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string alert_id = 3;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataRequest.prototype.getAlertId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.GetAlertContextDataRequest} returns this
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataRequest.prototype.setAlertId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.GetAlertContextDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    alert: (f = msg.getAlert()) && entity_pb.Alert.toObject(includeInstance, f),
    rangeLowerBound: (f = msg.getRangeLowerBound()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    rangeUpperBound: (f = msg.getRangeUpperBound()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    metricDataList: jspb.Message.toObjectList(msg.getMetricDataList(),
    proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.GetAlertContextDataResponse}
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.GetAlertContextDataResponse;
  return proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.GetAlertContextDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.GetAlertContextDataResponse}
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 2:
      var value = new entity_pb.Alert;
      reader.readMessage(value,entity_pb.Alert.deserializeBinaryFromReader);
      msg.setAlert(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRangeLowerBound(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRangeUpperBound(value);
      break;
    case 5:
      var value = new proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData;
      reader.readMessage(value,proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData.deserializeBinaryFromReader);
      msg.addMetricData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.GetAlertContextDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAlert();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      entity_pb.Alert.serializeBinaryToWriter
    );
  }
  f = message.getRangeLowerBound();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRangeUpperBound();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMetricDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData.prototype.toObject = function(opt_includeInstance) {
  return proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData.toObject = function(includeInstance, msg) {
  var f, obj = {
    metricType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    measuresList: jspb.Message.toObjectList(msg.getMeasuresList(),
    device_payload_pb.Measures.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData}
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData;
  return proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData}
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.com.google.scout.chorus.proto.ListMeasuresRequest.MetricType} */ (reader.readEnum());
      msg.setMetricType(value);
      break;
    case 2:
      var value = new device_payload_pb.Measures;
      reader.readMessage(value,device_payload_pb.Measures.deserializeBinaryFromReader);
      msg.addMeasures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetricType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMeasuresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      device_payload_pb.Measures.serializeBinaryToWriter
    );
  }
};


/**
 * optional ListMeasuresRequest.MetricType metric_type = 1;
 * @return {!proto.com.google.scout.chorus.proto.ListMeasuresRequest.MetricType}
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData.prototype.getMetricType = function() {
  return /** @type {!proto.com.google.scout.chorus.proto.ListMeasuresRequest.MetricType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.com.google.scout.chorus.proto.ListMeasuresRequest.MetricType} value
 * @return {!proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData} returns this
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData.prototype.setMetricType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated Measures measures = 2;
 * @return {!Array<!proto.com.google.scout.chorus.proto.Measures>}
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData.prototype.getMeasuresList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.Measures>} */ (
    jspb.Message.getRepeatedWrapperField(this, device_payload_pb.Measures, 2));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.Measures>} value
 * @return {!proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData} returns this
*/
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData.prototype.setMeasuresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.Measures=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.Measures}
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData.prototype.addMeasures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.google.scout.chorus.proto.Measures, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData} returns this
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData.prototype.clearMeasuresList = function() {
  return this.setMeasuresList([]);
};


/**
 * optional string next_page_token = 1;
 * @return {string}
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.google.scout.chorus.proto.GetAlertContextDataResponse} returns this
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Alert alert = 2;
 * @return {?proto.com.google.scout.chorus.proto.Alert}
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.prototype.getAlert = function() {
  return /** @type{?proto.com.google.scout.chorus.proto.Alert} */ (
    jspb.Message.getWrapperField(this, entity_pb.Alert, 2));
};


/**
 * @param {?proto.com.google.scout.chorus.proto.Alert|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.GetAlertContextDataResponse} returns this
*/
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.prototype.setAlert = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.GetAlertContextDataResponse} returns this
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.prototype.clearAlert = function() {
  return this.setAlert(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.prototype.hasAlert = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp range_lower_bound = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.prototype.getRangeLowerBound = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.GetAlertContextDataResponse} returns this
*/
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.prototype.setRangeLowerBound = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.GetAlertContextDataResponse} returns this
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.prototype.clearRangeLowerBound = function() {
  return this.setRangeLowerBound(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.prototype.hasRangeLowerBound = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp range_upper_bound = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.prototype.getRangeUpperBound = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.google.scout.chorus.proto.GetAlertContextDataResponse} returns this
*/
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.prototype.setRangeUpperBound = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.google.scout.chorus.proto.GetAlertContextDataResponse} returns this
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.prototype.clearRangeUpperBound = function() {
  return this.setRangeUpperBound(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.prototype.hasRangeUpperBound = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated AlertMetricData metric_data = 5;
 * @return {!Array<!proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData>}
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.prototype.getMetricDataList = function() {
  return /** @type{!Array<!proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData, 5));
};


/**
 * @param {!Array<!proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData>} value
 * @return {!proto.com.google.scout.chorus.proto.GetAlertContextDataResponse} returns this
*/
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.prototype.setMetricDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData}
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.prototype.addMetricData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.AlertMetricData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.google.scout.chorus.proto.GetAlertContextDataResponse} returns this
 */
proto.com.google.scout.chorus.proto.GetAlertContextDataResponse.prototype.clearMetricDataList = function() {
  return this.setMetricDataList([]);
};


goog.object.extend(exports, proto.com.google.scout.chorus.proto);
