/**
 * @fileoverview gRPC-Web generated client stub for com.google.scout.chorus.proto
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as entity_pb from './entity_pb';
import * as trip_api_pb from './trip_api_pb';
import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';


export class TripsClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListTrips = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Trips/ListTrips',
    grpcWeb.MethodType.UNARY,
    trip_api_pb.ListTripsRequest,
    trip_api_pb.ListTripsResponse,
    (request: trip_api_pb.ListTripsRequest) => {
      return request.serializeBinary();
    },
    trip_api_pb.ListTripsResponse.deserializeBinary
  );

  listTrips(
    request: trip_api_pb.ListTripsRequest,
    metadata: grpcWeb.Metadata | null): Promise<trip_api_pb.ListTripsResponse>;

  listTrips(
    request: trip_api_pb.ListTripsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: trip_api_pb.ListTripsResponse) => void): grpcWeb.ClientReadableStream<trip_api_pb.ListTripsResponse>;

  listTrips(
    request: trip_api_pb.ListTripsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: trip_api_pb.ListTripsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Trips/ListTrips',
        request,
        metadata || {},
        this.methodDescriptorListTrips,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Trips/ListTrips',
    request,
    metadata || {},
    this.methodDescriptorListTrips);
  }

  methodDescriptorListAssets = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Trips/ListAssets',
    grpcWeb.MethodType.UNARY,
    trip_api_pb.ListAssetsRequest,
    trip_api_pb.ListAssetsResponse,
    (request: trip_api_pb.ListAssetsRequest) => {
      return request.serializeBinary();
    },
    trip_api_pb.ListAssetsResponse.deserializeBinary
  );

  listAssets(
    request: trip_api_pb.ListAssetsRequest,
    metadata: grpcWeb.Metadata | null): Promise<trip_api_pb.ListAssetsResponse>;

  listAssets(
    request: trip_api_pb.ListAssetsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: trip_api_pb.ListAssetsResponse) => void): grpcWeb.ClientReadableStream<trip_api_pb.ListAssetsResponse>;

  listAssets(
    request: trip_api_pb.ListAssetsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: trip_api_pb.ListAssetsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Trips/ListAssets',
        request,
        metadata || {},
        this.methodDescriptorListAssets,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Trips/ListAssets',
    request,
    metadata || {},
    this.methodDescriptorListAssets);
  }

  methodDescriptorCreateTrip = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Trips/CreateTrip',
    grpcWeb.MethodType.UNARY,
    trip_api_pb.CreateTripRequest,
    entity_pb.Trip,
    (request: trip_api_pb.CreateTripRequest) => {
      return request.serializeBinary();
    },
    entity_pb.Trip.deserializeBinary
  );

  createTrip(
    request: trip_api_pb.CreateTripRequest,
    metadata: grpcWeb.Metadata | null): Promise<entity_pb.Trip>;

  createTrip(
    request: trip_api_pb.CreateTripRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: entity_pb.Trip) => void): grpcWeb.ClientReadableStream<entity_pb.Trip>;

  createTrip(
    request: trip_api_pb.CreateTripRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: entity_pb.Trip) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Trips/CreateTrip',
        request,
        metadata || {},
        this.methodDescriptorCreateTrip,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Trips/CreateTrip',
    request,
    metadata || {},
    this.methodDescriptorCreateTrip);
  }

  methodDescriptorUpdateTrip = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Trips/UpdateTrip',
    grpcWeb.MethodType.UNARY,
    trip_api_pb.UpdateTripRequest,
    entity_pb.Trip,
    (request: trip_api_pb.UpdateTripRequest) => {
      return request.serializeBinary();
    },
    entity_pb.Trip.deserializeBinary
  );

  updateTrip(
    request: trip_api_pb.UpdateTripRequest,
    metadata: grpcWeb.Metadata | null): Promise<entity_pb.Trip>;

  updateTrip(
    request: trip_api_pb.UpdateTripRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: entity_pb.Trip) => void): grpcWeb.ClientReadableStream<entity_pb.Trip>;

  updateTrip(
    request: trip_api_pb.UpdateTripRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: entity_pb.Trip) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Trips/UpdateTrip',
        request,
        metadata || {},
        this.methodDescriptorUpdateTrip,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Trips/UpdateTrip',
    request,
    metadata || {},
    this.methodDescriptorUpdateTrip);
  }

  methodDescriptorUpdateTripStage = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Trips/UpdateTripStage',
    grpcWeb.MethodType.UNARY,
    trip_api_pb.UpdateTripStageRequest,
    entity_pb.Trip,
    (request: trip_api_pb.UpdateTripStageRequest) => {
      return request.serializeBinary();
    },
    entity_pb.Trip.deserializeBinary
  );

  updateTripStage(
    request: trip_api_pb.UpdateTripStageRequest,
    metadata: grpcWeb.Metadata | null): Promise<entity_pb.Trip>;

  updateTripStage(
    request: trip_api_pb.UpdateTripStageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: entity_pb.Trip) => void): grpcWeb.ClientReadableStream<entity_pb.Trip>;

  updateTripStage(
    request: trip_api_pb.UpdateTripStageRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: entity_pb.Trip) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Trips/UpdateTripStage',
        request,
        metadata || {},
        this.methodDescriptorUpdateTripStage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Trips/UpdateTripStage',
    request,
    metadata || {},
    this.methodDescriptorUpdateTripStage);
  }

  methodDescriptorGetTrip = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Trips/GetTrip',
    grpcWeb.MethodType.UNARY,
    trip_api_pb.GetTripRequest,
    entity_pb.Trip,
    (request: trip_api_pb.GetTripRequest) => {
      return request.serializeBinary();
    },
    entity_pb.Trip.deserializeBinary
  );

  getTrip(
    request: trip_api_pb.GetTripRequest,
    metadata: grpcWeb.Metadata | null): Promise<entity_pb.Trip>;

  getTrip(
    request: trip_api_pb.GetTripRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: entity_pb.Trip) => void): grpcWeb.ClientReadableStream<entity_pb.Trip>;

  getTrip(
    request: trip_api_pb.GetTripRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: entity_pb.Trip) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Trips/GetTrip',
        request,
        metadata || {},
        this.methodDescriptorGetTrip,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Trips/GetTrip',
    request,
    metadata || {},
    this.methodDescriptorGetTrip);
  }

  methodDescriptorAssociateWithTrip = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Trips/AssociateWithTrip',
    grpcWeb.MethodType.UNARY,
    trip_api_pb.AssociateWithTripRequest,
    trip_api_pb.AssociateWithTripResponse,
    (request: trip_api_pb.AssociateWithTripRequest) => {
      return request.serializeBinary();
    },
    trip_api_pb.AssociateWithTripResponse.deserializeBinary
  );

  associateWithTrip(
    request: trip_api_pb.AssociateWithTripRequest,
    metadata: grpcWeb.Metadata | null): Promise<trip_api_pb.AssociateWithTripResponse>;

  associateWithTrip(
    request: trip_api_pb.AssociateWithTripRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: trip_api_pb.AssociateWithTripResponse) => void): grpcWeb.ClientReadableStream<trip_api_pb.AssociateWithTripResponse>;

  associateWithTrip(
    request: trip_api_pb.AssociateWithTripRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: trip_api_pb.AssociateWithTripResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Trips/AssociateWithTrip',
        request,
        metadata || {},
        this.methodDescriptorAssociateWithTrip,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Trips/AssociateWithTrip',
    request,
    metadata || {},
    this.methodDescriptorAssociateWithTrip);
  }

  methodDescriptorDeleteTrip = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Trips/DeleteTrip',
    grpcWeb.MethodType.UNARY,
    trip_api_pb.DeleteTripRequest,
    google_protobuf_empty_pb.Empty,
    (request: trip_api_pb.DeleteTripRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  deleteTrip(
    request: trip_api_pb.DeleteTripRequest,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  deleteTrip(
    request: trip_api_pb.DeleteTripRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  deleteTrip(
    request: trip_api_pb.DeleteTripRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Trips/DeleteTrip',
        request,
        metadata || {},
        this.methodDescriptorDeleteTrip,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Trips/DeleteTrip',
    request,
    metadata || {},
    this.methodDescriptorDeleteTrip);
  }

  methodDescriptorCreateAsset = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Trips/CreateAsset',
    grpcWeb.MethodType.UNARY,
    trip_api_pb.CreateAssetRequest,
    entity_pb.Asset,
    (request: trip_api_pb.CreateAssetRequest) => {
      return request.serializeBinary();
    },
    entity_pb.Asset.deserializeBinary
  );

  createAsset(
    request: trip_api_pb.CreateAssetRequest,
    metadata: grpcWeb.Metadata | null): Promise<entity_pb.Asset>;

  createAsset(
    request: trip_api_pb.CreateAssetRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: entity_pb.Asset) => void): grpcWeb.ClientReadableStream<entity_pb.Asset>;

  createAsset(
    request: trip_api_pb.CreateAssetRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: entity_pb.Asset) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Trips/CreateAsset',
        request,
        metadata || {},
        this.methodDescriptorCreateAsset,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Trips/CreateAsset',
    request,
    metadata || {},
    this.methodDescriptorCreateAsset);
  }

  methodDescriptorUpdateAsset = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Trips/UpdateAsset',
    grpcWeb.MethodType.UNARY,
    trip_api_pb.UpdateAssetRequest,
    entity_pb.Asset,
    (request: trip_api_pb.UpdateAssetRequest) => {
      return request.serializeBinary();
    },
    entity_pb.Asset.deserializeBinary
  );

  updateAsset(
    request: trip_api_pb.UpdateAssetRequest,
    metadata: grpcWeb.Metadata | null): Promise<entity_pb.Asset>;

  updateAsset(
    request: trip_api_pb.UpdateAssetRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: entity_pb.Asset) => void): grpcWeb.ClientReadableStream<entity_pb.Asset>;

  updateAsset(
    request: trip_api_pb.UpdateAssetRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: entity_pb.Asset) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Trips/UpdateAsset',
        request,
        metadata || {},
        this.methodDescriptorUpdateAsset,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Trips/UpdateAsset',
    request,
    metadata || {},
    this.methodDescriptorUpdateAsset);
  }

  methodDescriptorGetAsset = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Trips/GetAsset',
    grpcWeb.MethodType.UNARY,
    trip_api_pb.GetAssetRequest,
    entity_pb.Asset,
    (request: trip_api_pb.GetAssetRequest) => {
      return request.serializeBinary();
    },
    entity_pb.Asset.deserializeBinary
  );

  getAsset(
    request: trip_api_pb.GetAssetRequest,
    metadata: grpcWeb.Metadata | null): Promise<entity_pb.Asset>;

  getAsset(
    request: trip_api_pb.GetAssetRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: entity_pb.Asset) => void): grpcWeb.ClientReadableStream<entity_pb.Asset>;

  getAsset(
    request: trip_api_pb.GetAssetRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: entity_pb.Asset) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Trips/GetAsset',
        request,
        metadata || {},
        this.methodDescriptorGetAsset,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Trips/GetAsset',
    request,
    metadata || {},
    this.methodDescriptorGetAsset);
  }

  methodDescriptorAssociateWithAsset = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Trips/AssociateWithAsset',
    grpcWeb.MethodType.UNARY,
    trip_api_pb.AssociateWithAssetRequest,
    trip_api_pb.AssociateWithAssetResponse,
    (request: trip_api_pb.AssociateWithAssetRequest) => {
      return request.serializeBinary();
    },
    trip_api_pb.AssociateWithAssetResponse.deserializeBinary
  );

  associateWithAsset(
    request: trip_api_pb.AssociateWithAssetRequest,
    metadata: grpcWeb.Metadata | null): Promise<trip_api_pb.AssociateWithAssetResponse>;

  associateWithAsset(
    request: trip_api_pb.AssociateWithAssetRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: trip_api_pb.AssociateWithAssetResponse) => void): grpcWeb.ClientReadableStream<trip_api_pb.AssociateWithAssetResponse>;

  associateWithAsset(
    request: trip_api_pb.AssociateWithAssetRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: trip_api_pb.AssociateWithAssetResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Trips/AssociateWithAsset',
        request,
        metadata || {},
        this.methodDescriptorAssociateWithAsset,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Trips/AssociateWithAsset',
    request,
    metadata || {},
    this.methodDescriptorAssociateWithAsset);
  }

  methodDescriptorDissociateFromAsset = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Trips/DissociateFromAsset',
    grpcWeb.MethodType.UNARY,
    trip_api_pb.DissociateFromAssetRequest,
    trip_api_pb.DissociateFromAssetResponse,
    (request: trip_api_pb.DissociateFromAssetRequest) => {
      return request.serializeBinary();
    },
    trip_api_pb.DissociateFromAssetResponse.deserializeBinary
  );

  dissociateFromAsset(
    request: trip_api_pb.DissociateFromAssetRequest,
    metadata: grpcWeb.Metadata | null): Promise<trip_api_pb.DissociateFromAssetResponse>;

  dissociateFromAsset(
    request: trip_api_pb.DissociateFromAssetRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: trip_api_pb.DissociateFromAssetResponse) => void): grpcWeb.ClientReadableStream<trip_api_pb.DissociateFromAssetResponse>;

  dissociateFromAsset(
    request: trip_api_pb.DissociateFromAssetRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: trip_api_pb.DissociateFromAssetResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Trips/DissociateFromAsset',
        request,
        metadata || {},
        this.methodDescriptorDissociateFromAsset,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Trips/DissociateFromAsset',
    request,
    metadata || {},
    this.methodDescriptorDissociateFromAsset);
  }

  methodDescriptorDeleteAsset = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Trips/DeleteAsset',
    grpcWeb.MethodType.UNARY,
    trip_api_pb.DeleteAssetRequest,
    google_protobuf_empty_pb.Empty,
    (request: trip_api_pb.DeleteAssetRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  deleteAsset(
    request: trip_api_pb.DeleteAssetRequest,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  deleteAsset(
    request: trip_api_pb.DeleteAssetRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  deleteAsset(
    request: trip_api_pb.DeleteAssetRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Trips/DeleteAsset',
        request,
        metadata || {},
        this.methodDescriptorDeleteAsset,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Trips/DeleteAsset',
    request,
    metadata || {},
    this.methodDescriptorDeleteAsset);
  }

  methodDescriptorCountTrips = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Trips/CountTrips',
    grpcWeb.MethodType.UNARY,
    trip_api_pb.ListTripsRequest,
    trip_api_pb.CountTripsResponse,
    (request: trip_api_pb.ListTripsRequest) => {
      return request.serializeBinary();
    },
    trip_api_pb.CountTripsResponse.deserializeBinary
  );

  countTrips(
    request: trip_api_pb.ListTripsRequest,
    metadata: grpcWeb.Metadata | null): Promise<trip_api_pb.CountTripsResponse>;

  countTrips(
    request: trip_api_pb.ListTripsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: trip_api_pb.CountTripsResponse) => void): grpcWeb.ClientReadableStream<trip_api_pb.CountTripsResponse>;

  countTrips(
    request: trip_api_pb.ListTripsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: trip_api_pb.CountTripsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Trips/CountTrips',
        request,
        metadata || {},
        this.methodDescriptorCountTrips,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Trips/CountTrips',
    request,
    metadata || {},
    this.methodDescriptorCountTrips);
  }

  methodDescriptorGetEta = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Trips/GetEta',
    grpcWeb.MethodType.UNARY,
    trip_api_pb.GetEtaRequest,
    trip_api_pb.GetEtaResponse,
    (request: trip_api_pb.GetEtaRequest) => {
      return request.serializeBinary();
    },
    trip_api_pb.GetEtaResponse.deserializeBinary
  );

  getEta(
    request: trip_api_pb.GetEtaRequest,
    metadata: grpcWeb.Metadata | null): Promise<trip_api_pb.GetEtaResponse>;

  getEta(
    request: trip_api_pb.GetEtaRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: trip_api_pb.GetEtaResponse) => void): grpcWeb.ClientReadableStream<trip_api_pb.GetEtaResponse>;

  getEta(
    request: trip_api_pb.GetEtaRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: trip_api_pb.GetEtaResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Trips/GetEta',
        request,
        metadata || {},
        this.methodDescriptorGetEta,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Trips/GetEta',
    request,
    metadata || {},
    this.methodDescriptorGetEta);
  }

  methodDescriptorCreateTracking = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Trips/CreateTracking',
    grpcWeb.MethodType.UNARY,
    trip_api_pb.CreateTrackingRequest,
    entity_pb.Tracking,
    (request: trip_api_pb.CreateTrackingRequest) => {
      return request.serializeBinary();
    },
    entity_pb.Tracking.deserializeBinary
  );

  createTracking(
    request: trip_api_pb.CreateTrackingRequest,
    metadata: grpcWeb.Metadata | null): Promise<entity_pb.Tracking>;

  createTracking(
    request: trip_api_pb.CreateTrackingRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: entity_pb.Tracking) => void): grpcWeb.ClientReadableStream<entity_pb.Tracking>;

  createTracking(
    request: trip_api_pb.CreateTrackingRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: entity_pb.Tracking) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Trips/CreateTracking',
        request,
        metadata || {},
        this.methodDescriptorCreateTracking,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Trips/CreateTracking',
    request,
    metadata || {},
    this.methodDescriptorCreateTracking);
  }

  methodDescriptorStartTracking = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Trips/StartTracking',
    grpcWeb.MethodType.UNARY,
    trip_api_pb.StartTrackingRequest,
    entity_pb.Tracking,
    (request: trip_api_pb.StartTrackingRequest) => {
      return request.serializeBinary();
    },
    entity_pb.Tracking.deserializeBinary
  );

  startTracking(
    request: trip_api_pb.StartTrackingRequest,
    metadata: grpcWeb.Metadata | null): Promise<entity_pb.Tracking>;

  startTracking(
    request: trip_api_pb.StartTrackingRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: entity_pb.Tracking) => void): grpcWeb.ClientReadableStream<entity_pb.Tracking>;

  startTracking(
    request: trip_api_pb.StartTrackingRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: entity_pb.Tracking) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Trips/StartTracking',
        request,
        metadata || {},
        this.methodDescriptorStartTracking,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Trips/StartTracking',
    request,
    metadata || {},
    this.methodDescriptorStartTracking);
  }

  methodDescriptorEndTrackings = new grpcWeb.MethodDescriptor(
    '/com.google.scout.chorus.proto.Trips/EndTrackings',
    grpcWeb.MethodType.UNARY,
    trip_api_pb.EndTrackingsRequest,
    trip_api_pb.EndTrackingsResponse,
    (request: trip_api_pb.EndTrackingsRequest) => {
      return request.serializeBinary();
    },
    trip_api_pb.EndTrackingsResponse.deserializeBinary
  );

  endTrackings(
    request: trip_api_pb.EndTrackingsRequest,
    metadata: grpcWeb.Metadata | null): Promise<trip_api_pb.EndTrackingsResponse>;

  endTrackings(
    request: trip_api_pb.EndTrackingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: trip_api_pb.EndTrackingsResponse) => void): grpcWeb.ClientReadableStream<trip_api_pb.EndTrackingsResponse>;

  endTrackings(
    request: trip_api_pb.EndTrackingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: trip_api_pb.EndTrackingsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.google.scout.chorus.proto.Trips/EndTrackings',
        request,
        metadata || {},
        this.methodDescriptorEndTrackings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.google.scout.chorus.proto.Trips/EndTrackings',
    request,
    metadata || {},
    this.methodDescriptorEndTrackings);
  }

}

