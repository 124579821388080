import {NgModule} from '@angular/core';
import {DeviceAssetListComponent} from './device-asset-list.component';
import {BaseAssociatedEntityListModule} from '../../shared/base-associated-entity-list/base-associated-entity-list.module';
import {AssetListItemModule} from '../../shared/asset-list-item/asset-list-item.module';
import {SharedModule} from '../../shared/shared.module';

export const MODULE_DECLARATIONS = [DeviceAssetListComponent];

export const MODULE_IMPORTS = [
  AssetListItemModule,
  BaseAssociatedEntityListModule,
  SharedModule,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [DeviceAssetListComponent],
})
export class DeviceAssetListModule {}
