import { EnvironmentType } from './environment-type';

// Environment config for our dev environment.
export const environment = {
  environmentType: EnvironmentType.DEV,
  // While a bit of a misnomer, this controls whether a production-style build
  // is done. Thus, while this is not a true production environment, we want it
  // to closely mirror it.
  production: true,
  apiUrl: 'https://internal-dev.chorussystems.net',
  firebase: {
    apiKey: 'AIzaSyB9_V9cpBS4CVKpLTxE7LPAgxYtLRRz1io',
    authDomain: 'auth.chorussystems.net',
    databaseURL: 'https://chorus-scout-d78dc.firebaseio.com',
    projectId: 'chorus-scout',
    storageBucket: 'chorus-scout.appspot.com',
    messagingSenderId: '317301814798',
    appId: '1:317301814798:web:ba7f8fc6672f84190dee5f',
    measurementId: 'G-X0HNNT6J26',
  },
  googleAnalyticsId: 'UA-163651269-2',
  errorReportingApiKey: 'AIzaSyDF14a-CwDnEfhwTfQe294GtaoJNxDMiuc',
  scanditApiKey:
    'AZUkqQxeK4B3QucR+CssoeoESgdnR/g7VlcjPqtjsJqwLHnCLEGUoQxMd9vEdQAJxmeXNrwsb5/FKk/eSxLaqBBPJazBTWxQPXMM26t/EZLhQfvg+VpAl98G/aHfQvi2kkcYWwlNRsJuZC4a4kqfoXtHx0BbT2LzhHUeKzFagKYCdBxhS0hfYQ51V80daYGELk3IBaE3Jw0xfswsFW5sqTtkAqkxUN1LW1a9oat7XUn/ZyKzl33OEsFLCp6KfqPVMh6tjCFB23SrR0tNeW/vb5lhEqxQeStKKBVX+LVibyq2V8l0DXedne1iQDDtYsFgPWdkcXx9xpj4dslOUEWA/cB+a1gRRajU8msgF44n6DzDYcJflmVLESt/qreDern8Pl0uJE5HVWNSD22IvndErfpEqmZiUKQqA2s4hoogs0uRQoHcyhQaQJA8sFBuOOwX0jj5oasYw3/fiJtscFqv4wDEB/xNvfS1w30MMsZMDVrd0Xv3IAgJOrMDSYNQq388xPikYMlqQjpEtnpsqgWseMr5sj578hUxMrNkgt8e+IhJK1K7XzPLPBL5KtHsYMzSB9Z/z8VGoOMVg3iR2GLi3gNXbEU3PM83aV9D12UuH4RvLu+WVhLy2qIpFjiFQGu6sbTxcFhJ0odMaPgED3RoNzGg/srdO1cItdR+V9s0jh3TBwLQwtaGxPqsWhATwcP/Y2gssvPx23qOS0rAnHalnlyARBFML6P29KvNTcJ02oEkHgJdDF/ZDo1hwr5OiYwolL1S4jM7z/L39K5Kl+uI6FllciX0obA1t+2Q4PQnxU9uUqUn8+VxeYjw833j4XhDwoQe4aNzLKdINx72m9hsyzi+MqOa0buVzvFYj0Ubi6Z2HO9ikjAQE5WQ6Ce+w7gj1M6UNjKU38hVBOzomccW6Y/Rz6vn6OXcwCyHBMGtMJecCL9vIQQI1H8td7QWRC9rc5L6jL23ZneGdWhav7MjiXD/Gbz7Y2nBczKbbMSLYSgaisPxHoTz0Znr2f/lRFOHkILTsz9kRcV+qMiUnJfeYz/siZ1ltxbdQ7kPwGP4lkaldc189W+bX+DM7EMvL2ROA7a0ShmXWJkR+p+iiI+JRZneTFNNx4cNpD0SuXE=',
};
