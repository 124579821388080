import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {Asset} from 'src/app/jspb/entity_pb';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'device-asset-list',
  templateUrl: './device-asset-list.component.html',
  styleUrls: ['./device-asset-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceAssetListComponent implements OnChanges {
  @Input() assets: Asset[] | null;
  @Input() deviceId: string;
  @Output() reloadAssets: EventEmitter<void> = new EventEmitter();

  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.hasOwnProperty('assets')) {
      return;
    }
    // Loading is complete once we've received a new set of assets.
    this.loading$.next(false);
  }
}
